import { connect } from 'react-redux'

import { ProposalDashboard } from '@components/proposal'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'
import { HandleError } from '@coordinators/composed'
import { config } from 'hungry-core2'

const mapStateToProps = (state) => {
  const { proposals, proposalDashboard, proposalDashboardParams, user } = state
  const { emailProposalOn } = state.proposalPage
  const { isLoading } = state.proposalPage
  const apiUrl = `${config.api_host}/api/admin/`
  const proposalPdfUrlPrefix = `${apiUrl}proposals`

  return {
    loadingEmail: emailProposalOn,
    isLoading,
    proposals,
    proposalDashboard,
    proposalDashboardParams,
    proposalPdfUrlPrefix,
    userEmail: (user && user.email) || '',
  }
}

const mapDispatchToProps = () => {
  const { ProposalService, RestService, UIService } = services
  const {
    pResponseGeneric,
    pResponseProposal,
    pResponseProposals,
    pResponseOrderError,
    pRequestOrdersFilter: pRequestProposalsFilter,
  } = presenters.Api
  const {
    AsyncLoadLandingProposals,
    LoadDashboardProposals,
    SelectProposal,
    EmailProposal,
    ProposalAction,
    DeleteProposals,
  } = coordinators

  const loadLandingProposals = AsyncLoadLandingProposals({
    ProposalService,
    RestService,
    pResponseGeneric,
  })
  const loadProposals = LoadDashboardProposals({
    UIService,
    ProposalService,
    RestService,
    pRequestProposalsFilter,
    pResponseProposals,
  })
  const newProposal = () => UIService.EditProposal.show('new')
  const { clearOrderables: clearProposals } = ProposalService
  const proposalAction = ProposalAction({ RestService, UIService })
  const selectProposal = SelectProposal({
    ProposalService,
    RestService,
    pResponseProposal,
  })
  const deleteProposals = DeleteProposals({
    ProposalService,
    RestService,
    UIService,
    pResponseProposalError: pResponseOrderError,
    HandleError,
  })
  const emailProposal = EmailProposal({
    ProposalService,
    RestService,
    UIService,
    HandleError,
  })
  const { setLastLoadProposalDashboardParams } = UIService.ProposalDashboard

  return {
    clearProposals,
    loadLandingProposals,
    loadProposals,
    newProposal,
    proposalAction,
    selectProposal,
    deleteProposals,
    emailProposal,
    setLastLoadProposalDashboardParams,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalDashboard)
