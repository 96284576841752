import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarSearch from '@components/sidebar/SidebarSearch'
import SidebarListItem from '@components/sidebar/SidebarListItem'
import { Dropdown } from '@components/common/form'
import FlexContainer from '@components/common/FlexContainer'

export class AccountMaster extends Component {
  state = {
    search: '',
    sourceType: 'admin_panel',
  }

  componentDidMount() {
    const { filter } = this.props
    const { search, sourceType } = this.state

    this.props.loadAccounts({ status: filter, search, sourceType })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.props.filter) {
      const { search, sourceType } = this.state
      this.props.loadAccounts({ status: nextProps.filter, search, sourceType })
    }
  }

  onSelectAccount = (accountId) => {
    this.setState({ activeAccountId: accountId })
    this.props.selectAccount(accountId)
  }

  onSelectAccountType = (e) => {
    this.setState({ sourceType: e.target.value }, this.onDelaySearch)
  }

  onSearch = (e) => {
    const search = e.target.value
    this.setState({ search }, this.onDelaySearch)
  }

  onDelaySearch = () => {
    const { filter, loadAccounts } = this.props
    clearTimeout(this.searchTimer)

    this.searchTimer = setTimeout(() => {
      const { sourceType, search } = this.state
      const params = { status: filter, search }
      if (sourceType) {
        params.sourceType = sourceType
      }
      loadAccounts(params)
    }, 300)
  }

  render() {
    const { accounts } = this.props
    const { activeAccountId, sourceType } = this.state

    return (
      <div>
        <SidebarSearch label="Search Accounts" onChange={this.onSearch} />
        <FlexContainer padding="0px 10px 0px 10px">
          <Dropdown
            label="Creation Source"
            width="90%"
            value={sourceType}
            onChange={this.onSelectAccountType}
          >
            <option value=""> All </option>
            <option value="marketplace"> Marketplace </option>
            <option value="admin_panel"> Admin Panel </option>
          </Dropdown>
        </FlexContainer>
        {accounts.map((a) => {
          const selected = a.id === activeAccountId

          return (
            <SidebarListItem
              key={a.id}
              isSelected={selected}
              onClick={() => this.onSelectAccount(a.id)}
            >
              {a.name}
            </SidebarListItem>
          )
        })}
      </div>
    )
  }
}

AccountMaster.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.object,

  loadAccounts: PropTypes.func,
  selectAccount: PropTypes.func,
}

export default AccountMaster
