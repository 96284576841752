import { connect } from 'react-redux'

import { SuggestedConcepts } from '@components/order/edit/sections'

import services from '@services'
import presenters from '@presenters'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapDispatchToProps = () => {
  const { ChefService, OrderService, RestService, NodeApiService, UIService } =
    services
  const {
    AsyncLoadChefs,
    AsyncLoadCachedFilteredConcepts,
    GetConceptEstPricePerPerson,
    LoadAccountIntel,
    LoadConceptSuggestions,
    LoadProfileSuggestions,
  } = coordinators
  const {
    pCalculateConceptMargin,
    pCalculateConceptPPP,
    pResponseChefs,
    pRequestConceptSuggestions,
    pResponseFilteredConcepts,
    pRequestConcepts,
    pResponseGeneric,
    pGetMainMenuItems,
    pGetSideMenuItems,
    pGetOtherMenuItems,
    pRequestLoadCachedFilteredConcepts,
  } = presenters.Api

  const { prePopulateMenu } = OrderService

  const getConceptEstPricePerPerson = GetConceptEstPricePerPerson({
    RestService,
    HandleError,
  })
  const loadAccountIntel = LoadAccountIntel({ RestService, pResponseGeneric })
  const loadChefs = AsyncLoadChefs({
    ChefService,
    OrderService,
    RestService,
    pResponseChefs,
  })
  const loadCachedConcepts = AsyncLoadCachedFilteredConcepts({
    RestService,
    pRequestLoadCachedFilteredConcepts,
    pResponseGeneric,
    pResponseFilteredConcepts,
    HandleError,
  })
  const loadChefSuggestions = LoadConceptSuggestions({
    RestService,
    pRequestConceptSuggestions,
    pResponseGeneric,
    HandleError,
  })
  const loadProfileSuggestions = LoadProfileSuggestions({
    NodeApiService,
    RestService,
    UIService,
    pRequestConcepts,
    pResponseGeneric,
    HandleError,
  })

  const { showMenuDetails } = UIService.EditOrder

  return {
    getConceptEstPricePerPerson,
    showMenuDetails,
    loadAccountIntel,
    loadChefs,
    loadCachedConcepts,
    loadChefSuggestions,
    loadProfileSuggestions,
    pCalculateConceptMargin,
    pCalculateConceptPPP,
    pGetMainMenuItems,
    pGetSideMenuItems,
    pGetOtherMenuItems,
    prePopulateMenu,
  }
}

export default connect(undefined, mapDispatchToProps)(SuggestedConcepts)
