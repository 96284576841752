import { connect } from 'react-redux'

import { AccountDetail } from '@components/account'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { account } = state

  return { account }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService } = services
  const { pResponseAccount, pResponseAccounts } = presenters.Api
  const { LoadAccounts, SelectAccount } = coordinators

  const loadAccounts = LoadAccounts({
    AccountService,
    RestService,
    pResponseAccounts,
  })
  const selectAccount = SelectAccount({
    AccountService,
    RestService,
    pResponseAccount,
  })

  return {
    loadAccounts,
    selectAccount,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail)
