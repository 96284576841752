import * as accountActions from './account'
import * as accountsReceivableActions from './accountsReceivable'
import * as adminUserActions from './adminUser'
import * as batchOrderActions from './batchOrder'
import * as chefActions from './chef'
import * as clearActions from './clear'
import * as dashboardActions from './dashboard'
import * as groupOrderActions from './groupOrder'
import * as groupOrderPageActions from './groupOrderPage'
import * as headquarterActions from './headquarter'
import * as hubspotActions from './hubspot'
import * as invoiceActions from './invoice'
import * as marketActions from './market'
import * as orderActions from './order'
import * as promoCodeActions from './promoCodes'
import * as proposalActions from './proposal'
import * as receiptActions from './receipt'
import * as referralActions from './referral'
import * as salesStatsActions from './salesStats'
import * as serviceVendorsActions from './serviceVendor'
import * as settingsActions from './settings'
import * as subscriptionActions from './subscription'
import * as supplyActions from './supply'
import * as surveyActions from './survey'
import uiActions from './ui'

const actions = Object.freeze({
  ...accountActions,
  ...accountsReceivableActions,
  ...adminUserActions,
  ...batchOrderActions,
  ...chefActions,
  ...clearActions,
  ...dashboardActions,
  ...groupOrderActions,
  ...groupOrderPageActions,
  ...headquarterActions,
  ...hubspotActions,
  ...invoiceActions,
  ...marketActions,
  ...orderActions,
  ...promoCodeActions,
  ...proposalActions,
  ...receiptActions,
  ...referralActions,
  ...salesStatsActions,
  ...serviceVendorsActions,
  ...settingsActions,
  ...subscriptionActions,
  ...supplyActions,
  ...surveyActions,
  ...uiActions,
})

export default actions
