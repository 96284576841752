import { snakeCaseify, sortByAttribute } from '../../../utils'
import axios from 'axios'

export const LoadChefs =
  ({ ChefService, RestService, pResponseChefs }) =>
  async (filters) => {
    /* filters is object with shape { search, status }, both of which are optional */
    filters = snakeCaseify(filters)
    let chefs = await RestService.get('/api/admin/chefs', {
      page: 1,
      results_per_page: 5000,
      ...filters,
    })
    chefs = pResponseChefs(chefs)
    Array.isArray(chefs) && sortByAttribute(chefs, 'name')
    ChefService.setChefs(chefs)

    return chefs
  }

export const LoadChef =
  ({ RestService, pResponseChef }) =>
  async (chefId) => {
    let chef = await RestService.get(`/api/admin/chefs/${chefId}`)
    chef = pResponseChef(chef)

    return chef
  }

export const LoadExistingChefs =
  ({ RestService, pResponseChefs }) =>
  async (search) => {
    /* filters is object with shape { search, status }, both of which are optional */
    let chefs = await RestService.get('/api/admin/chefs', {
      page: 1,
      results_per_page: 5000,
      search,
      status: 'active',
      existing: true,
    })
    chefs = pResponseChefs(chefs)

    return chefs
  }

export const SearchPopUpAddOnChefs =
  ({ RestService, pResponseChefs }) =>
  async (filters) => {
    const chefs = await RestService.get('/api/admin/chefs', {
      page: 1,
      results_per_page: 5,
      pop_up_add_on_chefs: true,
      ...filters,
    })

    return pResponseChefs(chefs)
  }

export const SearchVXTalent =
  ({ RestService, pResponseChefs }) =>
  async (filters) => {
    const talent = await RestService.get('/api/admin/chefs', {
      page: 1,
      results_per_page: 5000,
      ...filters,
    })

    return pResponseChefs(talent)
  }

export const AsyncLoadLandingChefs =
  ({ RestService, pResponseGeneric, pRequestLandingChefs }) =>
  async (filters) => {
    const chefs = await RestService.get('/api/admin/landing', {
      view: 'chefs',
      ...pRequestLandingChefs(filters),
    })
    const chefResp = pResponseGeneric(chefs)
    Array.isArray(chefResp) && sortByAttribute(chefResp, 'name')

    return chefResp
  }

export const SelectChef =
  ({ ChefService, RestService, pResponseChef }) =>
  async (chefId) => {
    let chef = await RestService.get(`/api/admin/chefs/${chefId}`)
    chef = pResponseChef(chef)
    ChefService.setChef(chef)
  }

export const SelectExistingChef =
  ({ RestService, pResponseChef }) =>
  async (chefId) => {
    let chef = await RestService.get(`/api/admin/chefs/${chefId}`)
    chef = pResponseChef(chef)

    return chef
  }

export const EditChef =
  ({ ChefService, UIService }) =>
  () => {
    ChefService.setEditChef()
    UIService.EditChef.show()
  }

export const EditChildChefs =
  ({ ChefService, UIService }) =>
  () => {
    ChefService.setEditChef()
    UIService.EditChildChefs.show()
  }

export const CloseEditChildChefs =
  ({ ChefService, UIService }) =>
  () => {
    ChefService.clearEditChef()
    UIService.EditChildChefs.close()
  }

export const ShowCopyMenuItems =
  ({ UIService }) =>
  () => {
    UIService.CopyMenuItems.show()
  }

export const CloseCopyMenuItems =
  ({ UIService }) =>
  () => {
    UIService.CopyMenuItems.close()
  }

export const NewChef =
  ({ ChefService, UIService }) =>
  () => {
    ChefService.clearChef()
    ChefService.setEditChef()
    UIService.EditChef.show()
  }

export const DelayedUpdateChef =
  ({ ChefService, UIService, pStateToReduxChef }) =>
  (chef) => {
    const call = () => ChefService.updateEditChef(pStateToReduxChef(chef))
    UIService.Timer.callAfterTimeout(call)
  }

export const DeleteChef =
  ({ ChefService, RestService, UIService, pResponseChefs, HandleError }) =>
  async (id, firstName) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete chef "${firstName}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/v2/users/chefs/${id}`, { timeout: 60000 })
        ChefService.clearChef()
        LoadChefs({ ChefService, RestService, pResponseChefs })()
      } catch (error) {
        HandleError({ error })
      }
      UIService.EditChef.close()
    }
  }

export const SaveChef =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateChef,
    pResponseChef,
    HandleError,
  }) =>
  async (data) => {
    UIService.Errors.clear()
    UIService.EditChef.toggleLoading(true)
    const chefData = pRequestUpdateChef(data)
    const { addresses_attributes } = chefData
    const req = { chef: chefData }
    const headers = { 'Content-Type': 'multipart/form-data' }
    let chef = undefined

    if (data.image) {
      const file = data.image
      const presignedUrlData = {
        imageType: 'chef_profiles',
        mimeType: file.type,
      }
      const { presignedUrl, publicUrl } = await RestService.get(
        '/images/presigned',
        presignedUrlData,
      )

      const config = {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
        timeout: 120000,
      }

      let s3Response
      try {
        s3Response = await axios.put(presignedUrl, file, config)
      } catch (error) {
        HandleError({ error, namespace: 'chefModal' })
      }

      if (
        s3Response &&
        (s3Response.status === 200 || s3Response.statusText === 'OK')
      ) {
        req.chef.chef_profile_attributes.image_attributes = {
          remote_image_url: publicUrl,
        }
      }
    }

    try {
      if (data.id) {
        chef = await RestService.put(`/v2/users/chefs/${data.id}`, req, {
          ...headers,
          timeout: 60000,
        })

        // Update chef in future group order date menus
        await RestService.put(`/group-orders/date-menus/chefs/${data.id}`)
      } else {
        chef = await RestService.post('/v2/users/chefs', req, {
          ...headers,
          timeout: 60000,
        })
      }

      chef = pResponseChef(chef)
      ChefService.setChef(chef)
      UIService.EditChef.close()
      UIService.EditChef.toggleLoading(false)
      if (data.id && addresses_attributes && addresses_attributes.length > 0) {
        UIService.DisplayChefFutureOrders.show()
      }
    } catch (error) {
      HandleError({ error, namespace: 'chefModal' })
      UIService.EditChef.toggleLoading(false)
    }
  }

export const LoadChefContacts =
  ({
    RestService,
    ChefService,
    pRequestChefContacts,
    pResponseChefContacts,
    HandleError,
  }) =>
  async (chefId) => {
    const req = pRequestChefContacts(chefId)
    const headers = { 'Content-Type': 'multipart/form-data' }
    try {
      const { chef } = ChefService.getState()

      let chefContacts = await RestService.get(
        '/api/admin/chef_contacts',
        req,
        headers,
      )
      chefContacts = pResponseChefContacts(chefContacts)

      ChefService.setChef({
        ...chef,
        chefContacts,
      })

      return chefContacts
    } catch (error) {
      HandleError({ error })
    }
  }

export const NewChefContact =
  ({ ChefService, UIService }) =>
  () => {
    const { chef, editChefContact } = ChefService.getState()
    if (editChefContact.id) {
      ChefService.clearEditChefContact()
    }

    if (chef) {
      ChefService.setEditChefContact({
        chefId: chef.id,
        activeHqs: chef.headquarters.map((hq) => hq.id),
      })
      UIService.EditChefContact.show()
    }
  }

export const EditChefContact =
  ({ ChefService, UIService }) =>
  (chefContact) => {
    const { editChefContact } = ChefService.getState()
    if (editChefContact.id) {
      ChefService.clearEditChefContact()
    }
    ChefService.setEditChefContact(chefContact)
    UIService.EditChefContact.show()
  }

export const SaveChefContact =
  ({
    ChefService,
    RestService,
    UIService,
    pRequestUpdateChefContact,
    pResponseChef,
    HandleError,
  }) =>
  async (data) => {
    UIService.Errors.clear()
    const req = pRequestUpdateChefContact(data)
    const headers = { 'Content-Type': 'multipart/form-data' }

    try {
      if (data.id) {
        await RestService.put(
          `/api/admin/chef_contacts/${data.id}`,
          req,
          headers,
        )
      } else {
        await RestService.post('/api/admin/chef_contacts', req, headers)
      }
      await SelectChef({ ChefService, RestService, pResponseChef })(data.chefId)
      UIService.EditChefContact.close()
    } catch (error) {
      HandleError({ error, namespace: 'chefContactModal' })
    }
  }

export const DeleteChefContact =
  ({ RestService, UIService, ChefService, pResponseChef, HandleError }) =>
  async (contactId, chefId, name) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete contact "${name}"?`,
    })
    try {
      if (doDelete) {
        await RestService.delete(`/api/admin/chef_contacts/${contactId}`)
        await SelectChef({ ChefService, RestService, pResponseChef })(chefId)
      }
      UIService.EditChefContact.close()
    } catch (error) {
      HandleError({ error })
    }
  }

export const DelayedUpdateChefContact =
  ({ ChefService, UIService, pStateToReduxChefContact }) =>
  (chefContact) => {
    const call = () =>
      ChefService.updateEditChefContact(pStateToReduxChefContact(chefContact))
    UIService.Timer.callAfterTimeout(call)
  }

export const LoadChefOrders =
  ({ RestService, pRequestChefOrders, pResponseOrders, HandleError }) =>
  async ({
    chefId,
    calendarStartDate,
    calendarEndDate,
    withoutItems = false,
  }) => {
    const req = pRequestChefOrders(
      calendarStartDate,
      calendarEndDate,
      withoutItems,
    )
    try {
      const response = await RestService.get(
        `/chef-dashboard/chef/${chefId}`,
        req,
        { timeout: 30000 },
      )

      return pResponseOrders(response)
    } catch (error) {
      HandleError({ error })
    }
  }

export const LoadChefOrder =
  ({ RestService, pRequestChefOrder, pResponseChefOrder, HandleError }) =>
  async ({ orderId, chefId, orderType }) => {
    const req = pRequestChefOrder(chefId, orderType)
    try {
      const response = await RestService.get(
        `/chef-dashboard/${orderId}`,
        req,
        { timeout: 30000 },
      )

      return pResponseChefOrder(response)
    } catch (error) {
      HandleError({ error })
    }
  }

export const SendChefDashboardInvite =
  ({ RestService, UIService, HandleError }) =>
  async (email) => {
    try {
      await RestService.post('/api/admin/chef_dashboard/email_onboarding', {
        email,
      })
      UIService.FlashMessage.displaySuccessMessage('Invite email sent!')
    } catch (error) {
      HandleError({ error })
    }
  }

export const UploadChefLogoImage =
  ({ RestService, HandleError }) =>
  async (imageFile) => {
    let logoUrl
    try {
      if (imageFile !== undefined) {
        const file = imageFile
        // Get presigned url
        const presignedUrlData = { imageType: 'chef_logo', mimeType: file.type }
        const { presignedUrl, publicUrl } = await RestService.get(
          '/images/presigned',
          presignedUrlData,
        )
        logoUrl = publicUrl

        const config = {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
          timeout: 120000,
        }

        // Upload Image to S3
        let s3Response
        try {
          s3Response = await axios.put(presignedUrl, file, config)
        } catch (error) {
          throw new Error(`Upload to S3 failed. ${error}`)
        }

        if (
          s3Response &&
          (s3Response.status === 200 || s3Response.statusText === 'OK')
        ) {
          return logoUrl
        }
      }
    } catch (error) {
      HandleError({ error })

      return
    }

    return
  }

export const UploadChefCustomMenuImage =
  ({ RestService, HandleError }) =>
  async (imageFile) => {
    let customMenuImageUrl
    try {
      if (imageFile !== undefined) {
        const file = imageFile
        // Get presigned url
        const presignedUrlData = {
          imageType: 'chef_custom_menu_image',
          mimeType: file.type,
        }
        const { presignedUrl, publicUrl } = await RestService.get(
          '/images/presigned',
          presignedUrlData,
        )
        customMenuImageUrl = publicUrl

        const config = {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
          timeout: 120000,
        }

        // Upload Image to S3
        let s3Response
        try {
          s3Response = await axios.put(presignedUrl, file, config)
        } catch (error) {
          throw new Error(`Upload to S3 failed. ${error}`)
        }

        if (
          s3Response &&
          (s3Response.status === 200 || s3Response.statusText === 'OK')
        ) {
          return customMenuImageUrl
        }
      }
    } catch (error) {
      HandleError({ error })

      return
    }

    return
  }

export const CheckPdfImage =
  ({ RestService }) =>
  async (imageUrl) => {
    const resp = await RestService.get('/images/verify_pdf_image', { imageUrl })

    return resp.isValid
  }

export const SendChefSchedEmailReminder =
  ({ RestService, UIService, HandleError }) =>
  async (req) => {
    try {
      const resp = await RestService.post(
        '/group-orders/chefs/send-sched-email',
        req,
      )
      if (resp) {
        UIService.FlashMessage.displaySuccessMessage(
          `Email successfully sent to ${req.email}`,
        )
      }
    } catch (error) {
      HandleError({ error })
    }
  }

export const ValidatePickupTimes =
  ({ RestService, UIService, HandleError }) =>
  async (req) => {
    try {
      const warnings = await RestService.post(
        '/api/admin/chefs/validate_pickup_times',
        snakeCaseify(req),
      )
      if (Object.values(warnings).length === 0) {
        return true
      }

      const confirmed = await UIService.ConfirmationModal.show({
        text: `${Object.values(warnings).join(
          ', ',
        )} Are you sure you want to continue?`,
      })

      return confirmed
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const LoadChefFutureOrders =
  ({ RestService, pResponseGeneric, HandleError }) =>
  async (chefId) => {
    try {
      const params = { chefId }
      const orders = await RestService.get(`/chefs/future-orders`, params)

      return pResponseGeneric(orders)
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const UpdateUpcomingOrderAddresses =
  ({ RestService, UIService, HandleError }) =>
  async (req) => {
    try {
      const resp = await RestService.put(
        '/chefs/update-future-order-addresses',
        req,
      )
      const { errors, successes } = resp
      UIService.ResultsMessageModal.displayResultsMessageModal({
        show: true,
        errors,
        successes,
      })

      return true
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const SearchChefsForFeesModal =
  ({ RestService, pResponseChefs, HandleError }) =>
  async (filters) => {
    try {
      filters = snakeCaseify(filters)
      let chefs = await RestService.get('/api/admin/chefs', {
        page: 1,
        results_per_page: 5000,
        all_hqs: true,
        ...filters,
      })
      chefs = pResponseChefs(chefs)
      sortByAttribute(chefs, 'name')

      return chefs
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const GenerateChefProcessingFees =
  ({ RestService, HandleError }) =>
  async ({ chefId, processingDate, hqIds }) => {
    try {
      const req = { chefId, processingDate, hqIds }
      const resp = await RestService.post(
        '/accounting/chefs/payouts/create-processing-fee',
        req,
      )

      return resp
    } catch (error) {
      HandleError({ error })

      return undefined
    }
  }
