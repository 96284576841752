import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/common/modal/Modal'
import FlexContainer from '@components/common/FlexContainer'
import styled from '@emotion/styled'
import { colors } from '../../../../constants'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import LinkText from '@components/common/form/LinkText'
import Tooltip from '@components/common/Tooltip'
import { TechHelpForm } from '@components/common'
import { Checkbox, QuantityInput } from '@components/common/form'
import {
  FormInputContainer,
  MenuItemDropdown,
} from '@res/styledComponents/index'
import { isEmpty } from '~/utils'
import MenuItem from './sections/MenuItem'
import { AiOutlineDown } from 'react-icons/ai'
import { CloseX } from '@res/styledComponents/index'
import {
  MAIN,
  SIDE,
  STARCH_SIDE,
  VEGETABLE_SIDE,
  ADD_ON,
  BUILD_YOUR_OWN,
  MENU_DETAILS_SECTION_MODE_SELECT,
} from '../../../../constants'
import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError } from '@coordinators/composed'

const { RestService, OrderService, UIService } = services
const { LoadCateringMenuItems, GetConceptEstPricePerPerson } = coordinators
const {
  pResponseOrderSearchMenuItems,
  pCalculatePPP,
  pGetMainMenuItems,
  pGetSideMenuItems,
  pGetOtherMenuItems,
  pGetServingsPerPerson,
  pGetPricePerPerson,
} = presenters.Api
const { prePopulateMenu } = OrderService

const loadCateringMenuItems = LoadCateringMenuItems({
  RestService,
  pResponseOrderSearchMenuItems,
})
const getConceptEstPricePerPerson = GetConceptEstPricePerPerson({
  RestService,
  HandleError,
})

const displayAlert = ({ message, title }) =>
  UIService.Alert.showPopup({ message, title })

const onPrePopulateMenu = (
  mains,
  sides,
  isUpdatedStructure,
  isBYO,
  headCount,
  setSelectedMainsMap,
  setSelectedSidesMap,
  pricePerPersonData,
) => {
  const { prePopulatedMains, prePopulatedSides } = prePopulateMenu(
    mains,
    sides,
    isUpdatedStructure,
    isBYO,
    headCount,
    pricePerPersonData,
  )
  if (prePopulatedMains) {
    const menuItemMap = {}
    prePopulatedMains.forEach(
      (menuItem) => (menuItemMap[menuItem.id] = menuItem),
    )
    setSelectedMainsMap({ ...menuItemMap })
  }

  if (prePopulatedSides) {
    const menuItemMap = {}
    prePopulatedSides.forEach(
      (menuItem) => (menuItemMap[menuItem.id] = menuItem),
    )
    setSelectedSidesMap({ ...menuItemMap })
  }
}

const loadMenuItems = async (concept, setChefMenuItems) => {
  if (concept) {
    const { chefId } = concept

    const menuItems = await loadCateringMenuItems(chefId)

    setChefMenuItems(menuItems)
  }
}

const MenuDetailsModal = (props) => {
  const [chefMenuItems, setChefMenuItems] = useState({})
  const [selectedMainsMap, setSelectedMainsMap] = useState({}) // {id: menuItem}
  const [selectedSidesMap, setSelectedSidesMap] = useState({}) // {id: menuItem}
  const [selectedAddonsMap, setSelectedAddonsMap] = useState({}) // {id: menuItem}
  const [removedItemIdsMap, setRemovedItemIdsMap] = useState({}) // {id: bool}
  const [showMainsDropDown, setShowMainsDropDown] = useState(false)
  const [showVegSidesDropDown, setShowVegSidesDropDown] = useState(false)
  const [showStarchSidesDropDown, setShowStarchSidesDropDown] = useState(false)
  const [showAddOnsDropDown, setShowAddOnsDropDown] = useState(false)
  const [showSidesDropDown, setShowSidesDropDown] = useState(false)
  const [
    calculatedEstimatedPricePerPerson,
    setCalculatedEstimatedPricePerPerson,
  ] = useState(0)
  const [quantityErrors, setQuantityErrors] = useState({})

  const {
    mode,
    account,
    concept,
    headCount,
    closeMenuDetails,
    onAddConceptToOrder,
    onShowMenuDetails,
    navigateToNextSection,
  } = props

  const {
    id,
    chefName,
    estimatedPricePerPerson,
    name,
    isUpdatedStructure,
    conceptsMenuItems,
    tagsCuisineList,
  } = concept || {}

  const mains = conceptsMenuItems ? pGetMainMenuItems(conceptsMenuItems) : []
  const sides = conceptsMenuItems ? pGetSideMenuItems(conceptsMenuItems) : []
  const addons = conceptsMenuItems ? pGetOtherMenuItems(conceptsMenuItems) : []

  const isBYO = tagsCuisineList
    ? tagsCuisineList.includes(BUILD_YOUR_OWN)
    : false

  const isOverEstimatedPrice =
    account &&
    account.avgPricePerPerson &&
    calculatedEstimatedPricePerPerson > parseFloat(account.avgPricePerPerson)
      ? true
      : false
  const isSelectMode = mode === MENU_DETAILS_SECTION_MODE_SELECT

  //TODO: Revisit this hook
  useEffect(() => {
    if (concept) {
      onHandlePrePopulateMenu(concept)
    }
  }, [isSelectMode, isBYO, concept, headCount])

  useEffect(() => {
    if (isEmpty(selectedMainsMap) && isEmpty(selectedSidesMap)) {
      setCalculatedEstimatedPricePerPerson(estimatedPricePerPerson)
    } else {
      const selectedMains = Object.values(selectedMainsMap)
      const selectedSides = Object.values(selectedSidesMap)

      const calcEstPPP = pCalculatePPP(selectedMains, selectedSides, headCount)
      setCalculatedEstimatedPricePerPerson(calcEstPPP)
    }
  }, [estimatedPricePerPerson, selectedMainsMap, selectedSidesMap])

  const onHandlePrePopulateMenu = useCallback(
    async (concept) => {
      const mainItemIds = mains
        .filter((main) => !removedItemIdsMap[main.menuItemId])
        .sort((a, b) => a.position - b.position)
        .map((item) => item.menuItem.id)
      const sideItemIds = sides
        .filter((side) => !removedItemIdsMap[side.menuItemId])
        .sort((a, b) => a.position - b.position)
        .map((item) => item.menuItem.id)
      const pricePerPersonData = await getConceptEstPricePerPerson(
        mainItemIds,
        sideItemIds,
      )

      if (isSelectMode) {
        onPrePopulateMenu(
          mains,
          sides,
          isUpdatedStructure,
          isBYO,
          headCount,
          setSelectedMainsMap,
          setSelectedSidesMap,
          pricePerPersonData,
        )
        loadMenuItems(concept, setChefMenuItems)
      } else if (!isSelectMode && isBYO) {
        onPrePopulateMenu(
          mains,
          sides,
          isUpdatedStructure,
          isBYO,
          headCount,
          setSelectedMainsMap,
          setSelectedSidesMap,
          pricePerPersonData,
        )
      }
      setRemovedItemIdsMap({})
    },
    [
      isSelectMode,
      mains,
      sides,
      isUpdatedStructure,
      isBYO,
      headCount,
      removedItemIdsMap,
    ],
  )

  const onHide = () => {
    closeMenuDetails()
  }

  const onSelectMenu = () => {
    onShowMenuDetails(MENU_DETAILS_SECTION_MODE_SELECT)
  }

  const onSelectMenuItem = (menuItem) => {
    const { id, mealType } = menuItem

    if (mealType === MAIN) {
      if (menuItem) {
        setSelectedMainsMap({
          ...selectedMainsMap,
          [id]: {
            ...menuItem,
          },
        })
      }
    } else if (mealType.includes(SIDE)) {
      setSelectedSidesMap({
        ...selectedSidesMap,
        [id]: {
          ...menuItem,
        },
      })
    } else {
      setSelectedAddonsMap({
        ...selectedAddonsMap,
        [id]: {
          ...menuItem,
        },
      })
    }
  }

  const onAddConcept = async () => {
    const { id, chefId, name } = concept || {}
    const mains = Object.values(selectedMainsMap)
    const sides = Object.values(selectedSidesMap)
    const addons = Object.values(selectedAddonsMap)

    const menuItems = [...mains, ...sides, ...addons]

    await onAddConceptToOrder({
      conceptId: id,
      conceptName: name,
      menuItems,
      chefId,
      isBYO,
    })
    navigateToNextSection()
  }

  const onRemoveSelectedMenuItem = (id, mealType = '') => {
    if (mealType === MAIN) {
      const { [id]: _, ...remainingMenuItems } = selectedMainsMap

      setSelectedMainsMap({ ...remainingMenuItems })
    } else if (mealType.includes(SIDE)) {
      const { [id]: _, ...remainingMenuItems } = selectedSidesMap

      setSelectedSidesMap({ ...remainingMenuItems })
    } else {
      const { [id]: _, ...remainingMenuItems } = selectedAddonsMap

      setSelectedAddonsMap({ ...remainingMenuItems })
    }
  }

  const onToggleRemoveMenuItem = (menuItemId) => {
    const nextRemovedItemIdsMap = { ...removedItemIdsMap }
    nextRemovedItemIdsMap[menuItemId] = !nextRemovedItemIdsMap[menuItemId]
    setRemovedItemIdsMap(nextRemovedItemIdsMap)
  }

  const onEditMenuItemQuantity =
    (id, mealType = '') =>
    (value) => {
      if (mealType === MAIN) {
        const menuItem = selectedMainsMap[id]
        if (menuItem) {
          setSelectedMainsMap({
            ...selectedMainsMap,
            [menuItem.id]: {
              ...menuItem,
              quantity: value,
            },
          })
        }
      } else if (mealType.includes(SIDE)) {
        const menuItem = selectedSidesMap[id]
        if (menuItem) {
          setSelectedSidesMap({
            ...selectedSidesMap,
            [menuItem.id]: {
              ...menuItem,
              quantity: value,
            },
          })
        }
      } else {
        const menuItem = selectedAddonsMap[id]
        if (menuItem) {
          setSelectedAddonsMap({
            ...selectedAddonsMap,
            [menuItem.id]: {
              ...menuItem,
              quantity: value,
            },
          })
        }
      }
    }

  const onBlurQuantity =
    (id, mealType = '') =>
    (value) => {
      let menuItem
      if (mealType === MAIN) {
        menuItem = selectedMainsMap[id]
      } else if (mealType.includes(SIDE)) {
        menuItem = selectedSidesMap[id]
      } else {
        menuItem = selectedAddonsMap[id]
      }

      if (menuItem) {
        const minQty = menuItem.minQty || 0
        if (value < minQty) {
          displayAlert({
            message: `Minimum quantity of ${minQty} for ${menuItem.name}`,
            title: 'Quantity Validation',
          })
          setQuantityErrors({ ...quantityErrors, [id]: true })
        }
      }
    }

  const onCloseQuantityError = (id) => {
    delete quantityErrors[id]
    setQuantityErrors({ ...quantityErrors })
  }

  const renderItemInfoTooltip = (menuItem) => {
    const {
      description,
      servingSize,
      servingsPerPkg,
      pricePerPersonCalc,
      price,
      marketPrice,
    } = menuItem
    const pricedPerPkg = pricePerPersonCalc === 'PerPackage'
    const itemPrice = Number(price || marketPrice)
    const pricePerServing =
      pricedPerPkg && servingsPerPkg > 0
        ? `${(itemPrice / servingsPerPkg).toFixed(2)} ($${itemPrice.toFixed(
            2,
          )} Per Pkg)`
        : itemPrice.toFixed(2)

    return (
      <FlexContainer flexDirection="column">
        <p className="font-bold">Price Per Serving:</p>
        <p className="ml-3">${pricePerServing}</p>
        <YSpacing height="10px" />
        <p className="font-extrabold">Description:</p>
        <p className="ml-3">{description}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Servings Size:</p>
        <p className="ml-3">{servingSize || 'N/A'}</p>
        <YSpacing height="10px" />
        <p className="font-bold">Servings Per Pkg:</p>
        <p className="ml-3">{servingsPerPkg}</p>
      </FlexContainer>
    )
  }

  const renderItemInternalNotesTooltip = (menuItem) => {
    const { internalNotes } = menuItem

    return (
      <FlexContainer flexDirection="column">
        <p className="font-extrabold">Internal Notes</p>
        <p>{internalNotes}</p>
      </FlexContainer>
    )
  }

  const renderMenuItem = (menuItem = {}, key) => {
    const { id, mealType, name, quantity, tagsDietaryPreferenceList, minQty } =
      menuItem
    const servPerPerson = pGetServingsPerPerson(menuItem, headCount).toFixed(1)
    const pricePerPerson = pGetPricePerPerson(menuItem, headCount).toFixed(2)

    return (
      <div key={key}>
        <FlexContainer flexDirection="row" alignItems="center">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {isSelectMode && (
              <>
                <CloseX onClick={() => onRemoveSelectedMenuItem(id, mealType)}>
                  ✕
                </CloseX>
                <XSpacing width="1px" />
                {quantityErrors[id] && (
                  <div
                    className="menu-item-tooltip cursor-pointer"
                    onClick={() => onCloseQuantityError(id)}
                  >
                    <p>This quantity is below the minimum servings.</p>
                  </div>
                )}
                <QuantityInput
                  name={name}
                  value={quantity}
                  width="50px"
                  onChange={onEditMenuItemQuantity(id, mealType)}
                  onBlur={onBlurQuantity(id, mealType)}
                />
                <XSpacing width="5px" />
              </>
            )}
            {!isSelectMode && (
              <Checkbox
                checked={!removedItemIdsMap[id]}
                onChange={() => onToggleRemoveMenuItem(id)}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="concept-item-bold">{name}</p>
            <Tooltip
              width="500px"
              iconType="info"
              margin="0 0 0 10px"
              isTooltipBottom={true}
            >
              {renderItemInfoTooltip(menuItem)}
            </Tooltip>
            <Tooltip width="500px" iconType="notes" margin="0 0 0 10px">
              {renderItemInternalNotesTooltip(menuItem)}
            </Tooltip>
          </div>
        </FlexContainer>
        {quantity > 0 && (
          <p className="concept-item-per-person-txt">
            {servPerPerson} servings / ${pricePerPerson} per person
          </p>
        )}
        {minQty && minQty > 0 && (
          <p className="concept-item-min-qty">
            {`Minimum quantity of ${minQty}`}
          </p>
        )}
        <p className="concept-item-dietary-tags">{tagsDietaryPreferenceList}</p>
        <YSpacing height="10px" />
      </div>
    )
  }

  const renderSideDetails = () => {
    const starchSides = sides.filter(
      (m) => m.menuItem && m.menuItem.mealType === STARCH_SIDE,
    )
    const vegetableSides = sides.filter(
      (m) => m.menuItem && m.menuItem.mealType === VEGETABLE_SIDE,
    )

    return (
      <div>
        {isUpdatedStructure ? (
          <FlexContainer flexDirection="column">
            <FlexContainer flexDirection="column">
              <p className="concept-item-heading">Starch Sides</p>
              {starchSides.map((conceptItem, index) =>
                renderMenuItem(conceptItem.menuItem, index),
              )}
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <p className="concept-item-heading">Veg Sides</p>
              {vegetableSides.map((conceptItem, index) =>
                renderMenuItem(conceptItem.menuItem, index),
              )}
            </FlexContainer>
          </FlexContainer>
        ) : (
          <FlexContainer flexDirection="column">
            <p className="concept-item-heading">Sides</p>
            {sides.map((conceptItem, index) =>
              renderMenuItem(conceptItem.menuItem, index),
            )}
          </FlexContainer>
        )}
      </div>
    )
  }

  const renderMainDetails = () => {
    return (
      <FlexContainer flexDirection="column">
        <p className="concept-item-heading">Mains</p>
        {mains.map((conceptItem, index) =>
          renderMenuItem(conceptItem.menuItem, index),
        )}
      </FlexContainer>
    )
  }

  const renderAddonsDetails = () => {
    return (
      <FlexContainer flexDirection="column">
        <p className="concept-item-heading">Addons</p>
        {addons.map((conceptItem, index) =>
          renderMenuItem(conceptItem.menuItem, index),
        )}
      </FlexContainer>
    )
  }

  const renderMenuDetails = () => {
    return (
      <FlexContainer flexDirection="column">
        {mains.length > 0 && renderMainDetails()}
        {isBYO
          ? renderBYOChildItems()
          : sides.length > 0 && renderSideDetails()}
        {addons.length > 0 && renderAddonsDetails()}
      </FlexContainer>
    )
  }

  const renderBYOChildItems = () => {
    const childItemsMap = {}

    mains.forEach((conceptItem) => {
      const { menuItem } = conceptItem

      menuItem.childItems.forEach((childItem) => {
        const { name } = childItem

        childItemsMap[name] = childItem
      })
    })

    const childItems = Object.values(childItemsMap)

    return (
      <FlexContainer flexDirection="column">
        <p className="concept-item-heading">Base, Toppings, Dressings</p>
        {childItems.map((menuItem, index) => {
          const { name, subMenuItemCategory } = menuItem

          return (
            <div key={index}>
              <FlexContainer flexDirection="row" alignItems="center">
                <p className="concept-child-item-bold">{subMenuItemCategory}</p>
                <XSpacing width="7px" />
                <p className="concept-item">{name}</p>
              </FlexContainer>
            </div>
          )
        })}
      </FlexContainer>
    )
  }

  const renderMenuItemDropDown = (type) => {
    let menuItems = []
    const {
      entrees = [],
      sides = [],
      desserts = [],
      drinks = [],
      appetizers = [],
    } = chefMenuItems

    let dropDownFunc = () => undefined
    let dropDownState = false
    if (type === MAIN) {
      menuItems = entrees
      dropDownFunc = setShowMainsDropDown
      dropDownState = showMainsDropDown
    } else if (type === STARCH_SIDE) {
      menuItems = sides.filter((menuItem) => menuItem.mealType === STARCH_SIDE)
      dropDownState = showStarchSidesDropDown
      dropDownFunc = setShowStarchSidesDropDown
    } else if (type === VEGETABLE_SIDE) {
      menuItems = sides.filter(
        (menuItem) => menuItem.mealType === VEGETABLE_SIDE,
      )
      dropDownState = showVegSidesDropDown
      dropDownFunc = setShowVegSidesDropDown
    } else if (type === ADD_ON) {
      menuItems = [...desserts, ...drinks, ...appetizers]
      dropDownState = showAddOnsDropDown
      dropDownFunc = setShowAddOnsDropDown
    } else if (type.includes(SIDE)) {
      menuItems = sides.filter(
        (menuItem) => menuItem.mealType && menuItem.mealType.includes(SIDE),
      )
      dropDownState = showSidesDropDown
      dropDownFunc = setShowSidesDropDown
    }

    return (
      <FlexContainer alignItems="flex-start">
        <FormInputContainer width="90%" marginBottom="20px">
          <DropDownBox>
            <p
              style={{ width: '100%' }}
              onClick={() => dropDownFunc(true)}
            >{`Search ${type} Items`}</p>
            {dropDownState ? (
              <ClearInput onClick={() => dropDownFunc(false)}>X</ClearInput>
            ) : (
              <AiOutlineDown onClick={() => dropDownFunc(true)} />
            )}
          </DropDownBox>
          {dropDownState && (
            <MenuItemDropdown>
              {menuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.id}
                  menuItem={menuItem}
                  onSelect={() => onSelectMenuItem(menuItem)}
                />
              ))}
            </MenuItemDropdown>
          )}
        </FormInputContainer>
      </FlexContainer>
    )
  }

  const renderSelectedMenu = () => {
    const mains = []

    for (const id in selectedMainsMap) {
      mains.push(renderMenuItem(selectedMainsMap[id], id))
    }

    const sides = []
    for (const id in selectedSidesMap) {
      sides.push(renderMenuItem(selectedSidesMap[id], id))
    }

    const addons = []
    for (const id in selectedAddonsMap) {
      addons.push(renderMenuItem(selectedAddonsMap[id], id))
    }

    const totalMainsServings = Object.values(selectedMainsMap).reduce(
      (sum, main) => {
        return sum + pGetServingsPerPerson(main, headCount)
      },
      0.0,
    )
    const totalMainsPPP = Object.values(selectedMainsMap).reduce(
      (sum, main) => {
        return sum + pGetPricePerPerson(main, headCount)
      },
      0.0,
    )
    const totalSidesServings = Object.values(selectedSidesMap).reduce(
      (sum, side) => {
        return sum + pGetServingsPerPerson(side, headCount)
      },
      0.0,
    )
    const totalSidesPPP = Object.values(selectedSidesMap).reduce(
      (sum, side) => {
        return sum + pGetPricePerPerson(side, headCount)
      },
      0.0,
    )

    return (
      <FlexContainer flexDirection="column">
        {mains.length > 0 && (
          <div>
            <p className="concept-item-heading">
              <FlexContainer flexDirection="row">
                Mains
                {(totalMainsServings > 0 || totalMainsPPP > 0) && (
                  <FlexContainer flexDirection="column">
                    <MealTypePerPersonData>
                      {totalMainsServings > 0 && (
                        <p className="concept-item-per-person-txt ml-4">
                          {totalMainsServings.toFixed(1)} servings per person
                        </p>
                      )}
                      {totalMainsPPP > 0 && (
                        <p className="concept-item-per-person-txt ml-4">
                          ${totalMainsPPP.toFixed(2)} per person
                        </p>
                      )}
                    </MealTypePerPersonData>
                  </FlexContainer>
                )}
              </FlexContainer>
            </p>
            {mains}
          </div>
        )}
        {sides.length > 0 && (
          <div>
            <p className="concept-item-heading">
              <FlexContainer flexDirection="row">
                Sides
                {(totalSidesServings > 0 || totalSidesPPP > 0) && (
                  <FlexContainer flexDirection="column">
                    <MealTypePerPersonData>
                      {totalSidesServings > 0 && (
                        <p className="concept-item-per-person-txt ml-4">
                          {totalSidesServings.toFixed(1)} servings per person
                        </p>
                      )}
                      {totalSidesPPP > 0 && (
                        <p className="concept-item-per-person-txt ml-4">
                          ${totalSidesPPP.toFixed(2)} per person
                        </p>
                      )}
                    </MealTypePerPersonData>
                  </FlexContainer>
                )}
              </FlexContainer>
            </p>
            {sides}
          </div>
        )}
        {addons.length > 0 && (
          <div>
            <p className="concept-item-heading">Addons</p>
            {addons}
          </div>
        )}
      </FlexContainer>
    )
  }

  const renderMenuItemDropDowns = () => {
    return (
      <FlexContainer flexDirection="column">
        <YSpacing height="30px" />
        <p className="title-orange">Add/Swap Other Menu Items</p>
        <YSpacing height="10px" />
        <FlexContainer flexDirection="row">
          <FlexContainer flexDirection="column" width="100%">
            {isUpdatedStructure
              ? renderMenuItemDropDown(STARCH_SIDE)
              : renderMenuItemDropDown(SIDE)}
            {renderMenuItemDropDown(MAIN)}
            {renderMenuItemDropDown(ADD_ON)}
          </FlexContainer>
          {isUpdatedStructure && (
            <FlexContainer flexDirection="column" width="100%">
              {renderMenuItemDropDown(VEGETABLE_SIDE)}
              {renderMenuItemDropDown(SIDE)}
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>
    )
  }

  return (
    <Modal
      showOverflow={true}
      title={isSelectMode ? 'Selected Menu' : 'Viewing Menu Details'}
      hideModal={onHide}
      color="#001940"
      width="600px"
    >
      {id ? (
        <MenuDetails>
          <FlexContainer flexDirection="row">
            <FlexContainer flexDirection="column" width="100%">
              <p className="concept-title">{`Chef ${chefName}'s ${name}`}</p>
              <FlexContainer flexDirection="row" alignItems="center">
                <p>Est. Price Per Person:</p>
                <EstimatedNumberDiv isOverEstimatedPrice={isOverEstimatedPrice}>
                  <p style={{ color: '#fff', fontWeight: 'bold' }}>
                    {' '}
                    ${calculatedEstimatedPricePerPerson}{' '}
                  </p>
                </EstimatedNumberDiv>
                {isOverEstimatedPrice && (
                  <Tooltip
                    width="300px"
                    isTooltipBottom={true}
                    iconType="alert"
                    margin="0 0 0 10px"
                  >
                    <p>
                      Please be aware the price per person has changed due to:
                    </p>
                    <p style={{ marginTop: '10px' }}>
                      {' '}
                      1. Adding or removing mains from the original mains on the
                      menu and/or
                    </p>
                    <p style={{ marginTop: '10px' }}>
                      2. Adding or additional sides in addition to having 1
                      starch & 1 veg side
                    </p>
                  </Tooltip>
                )}
              </FlexContainer>
              {isSelectMode && (
                <PortioningWarning>
                  Please review and verify that these suggested quantities meet
                  your specific market or client needs. These auto-filled
                  quantities are calculated based on our evolving standard
                  HUNGRY formula for catering orders.
                </PortioningWarning>
              )}
              <YSpacing height="30px" />
              {isSelectMode ? renderSelectedMenu() : renderMenuDetails()}
            </FlexContainer>
          </FlexContainer>
          {isSelectMode && renderMenuItemDropDowns()}
          <YSpacing height="20px" />
          <FlexContainer flexDirection="row" justifyContent="flex-end">
            <TechHelpForm />
            <XSpacing width="15px" />
            <LinkText
              fontSize="15px"
              onClick={onHide}
              label="Close"
              textDecoration={'underline'}
              color={colors.violet}
            />
            <XSpacing width="15px" />
            {isSelectMode || isBYO ? (
              <LinkText
                fontSize="15px"
                onClick={onAddConcept}
                label="Add Menu To Order"
                textDecoration={'underline'}
              />
            ) : (
              <LinkText
                fontSize="15px"
                onClick={onSelectMenu}
                label="Select Menu"
                textDecoration={'underline'}
              />
            )}
          </FlexContainer>
        </MenuDetails>
      ) : (
        <p>Loading....</p>
      )}
    </Modal>
  )
}

const MenuDetails = styled.div`
  .concept-title {
    font-family: 'bold';
    font-size: 25px;
  }
  .concept-item-heading {
    font-family: 'bold';
    font-size: 20px;
    color: ${colors.blue200};
  }
  .title-orange {
    font-family: 'bold';
    font-size: 22px;
    color: ${colors.orange};
  }
  .concept-item-bold {
    font-family: 'bold';
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
  .concept-child-item-bold {
    font-family: 'bold';
    font-size: 16px;
  }
  .concept-item {
    font-size: 16px;
  }
  .concept-item-dietary-tags {
    font-family: 'light';
    font-size: 13px;
  }
  .concept-item-per-person-txt {
    font-family: 'bold';
    font-size: 13px;
    padding-right: 10px;
  }
  .concept-item-min-qty {
    font-family: 'light';
    font-size: 13px;
    color: red;
  }
`

const PortioningWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: 'bold';
  text-align: center;
  padding: 10px;
  background-color: red;
  color: white;
`

const EstimatedNumberDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60px;
  height: 20px;
  background-color: ${(props) =>
    props.isOverEstimatedPrice ? 'red' : colors.green200};
`

const MealTypePerPersonData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: ${colors.green200};
`

export const ClearInput = styled.button`
  position: absolute;
  right: 10px;
  top: 7px;
`

export const DropDownBox = styled.div`
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafbff;
  .label {
    font-family: 'bold';
  }
`

MenuDetailsModal.propTypes = {
  mode: PropTypes.string,
  headCount: PropTypes.number,
  account: PropTypes.object,
  concept: PropTypes.object,

  onAddConceptToOrder: PropTypes.func,
  closeMenuDetails: PropTypes.func,
  onShowMenuDetails: PropTypes.func,
  navigateToNextSection: PropTypes.func,
}

export default MenuDetailsModal
