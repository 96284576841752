import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import { BiPencil } from 'react-icons/bi'
import { colors } from '../../../constants'

const Panel = (props) => {
  const {
    children,
    heading,
    maxWidth,
    minWidth,
    isScroll,
    isEdit,
    width,
    onEditCallBack,
  } = props

  return (
    <PanelContainer
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      isScroll={isScroll}
    >
      <FlexContainer flexDirection="row" justifyContent="space-between">
        <h2 className="sidebar-body-heading">{heading}</h2>
        {isEdit && (
          <button
            onClick={onEditCallBack}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <BiPencil />
            Edit
          </button>
        )}
      </FlexContainer>
      {heading && heading.length > 0 && <DividerLine margin="20px 0" />}
      {children}
    </PanelContainer>
  )
}

const PanelContainer = styled.div`
  -webkit-column-break-inside: avoid;
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  page-break-inside: avoid;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  /* display: inline-flex;
  flex-direction: column; */
  padding: 25px;
  position: relative;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow: ${(props) => (props.isScroll ? 'scroll' : 'initial')};
  .sidebar-body-heading {
    color: ${colors.orange};
    font-family: 'bold';
    text-transform: uppercase;
  }
`

Panel.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  heading: PropTypes.string,
  isScroll: PropTypes.bool,
  isEdit: PropTypes.bool,

  onEditCallBack: PropTypes.func,
}

Panel.defaultProps = {
  width: '100%',
}

export default Panel
