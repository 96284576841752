import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BiPencil } from 'react-icons/bi'
import { AuthorizedInteractable } from '@containers/common/auth'
import AccountProfileListGroup from './AccountProfileListGroup'
import { ColumnContainer, ButtonsBar } from '@res/styledComponents/index'

export class AccountProfile extends Component {
  state = {}

  componentDidMount() {
    const { account } = this.props
    this.loadAccountStats(account.id)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.account &&
      this.props.account &&
      nextProps.account.id !== this.props.account.id
    ) {
      this.loadAccountStats(nextProps.account.id)
    }
  }

  onEditAccount = (e) => {
    e.preventDefault()

    this.props.editAccount()
  }

  loadAccountStats = async (accountId) => {
    const stats = await this.props.loadAccountStats(accountId)
    if (stats) {
      this.setState({ stats })
    }
  }

  render() {
    const { account } = this.props
    const { stats } = this.state

    return (
      <div>
        <ButtonsBar>
          <AuthorizedInteractable
            roles={['master admin', 'sales rep', 'sales lead']}
          >
            <button onClick={this.onEditAccount}>
              <BiPencil />
              Edit
            </button>
          </AuthorizedInteractable>
        </ButtonsBar>
        <ColumnContainer>
          <AccountProfileListGroup accountProfile={{ ...account, ...stats }} />
        </ColumnContainer>
      </div>
    )
  }
}

AccountProfile.propTypes = {
  account: PropTypes.object,

  editAccount: PropTypes.func,
  loadAccountStats: PropTypes.func,
}

export default AccountProfile
