import { connect } from 'react-redux'

import { OrderHistory } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { account } = state

  return {
    accountId: account && account.id,
  }
}

const mapDispatchToProps = () => {
  const { UIService, RestService } = services
  const { pBuildGetRequest, pRequestOrdersFilter, pResponseOrders } =
    presenters.Api
  const { GetOrderCount, SearchOrders } = coordinators

  const buildGetRequest = pBuildGetRequest
  const searchOrders = SearchOrders({
    RestService,
    pRequestOrdersFilter: pRequestOrdersFilter,
    pResponseOrders,
  })
  const { callAfterTimeout } = UIService.Timer
  const getOrderCount = GetOrderCount({ RestService, pRequestOrdersFilter })

  return {
    buildGetRequest,
    callAfterTimeout,
    getOrderCount,
    searchOrders,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)
