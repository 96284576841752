import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import LinkText from '@components/common/form/LinkText'
import FlexContainer from '@components/common/FlexContainer'
import Dropdown from '@components/common/form/Dropdown'
import { LoadingIndicator } from '@components/common'
import styled from '@emotion/styled'
import { AutocompleteInput, Button } from '@components/common/form'
import {
  MENU_DETAILS_SECTION_MODE_VIEW,
  MENU_DETAILS_SECTION_MODE_SELECT,
  BUILD_YOUR_OWN,
} from '../../../../../constants'
import { BiDollar, BiUser } from 'react-icons/bi'
import {
  pGetMainMenuItems,
  pGetSideMenuItems,
} from '~/behavior/presenters/api/chefConcept'
// import { TbChefHat, TbArrowRight, TbBuilding } from 'react-icons/tb' TODO: Uncomment for MBP4-2

const conceptFiltersMap = {
  'High to Low Margin': { sortOrder: -1, sortBy: 'margin' },
  'Low to High Margin': { sortOrder: 1, sortBy: 'margin' },
}

const MENU_PREVIEW = 'MENU_PREVIEW'
const REVIEWS = 'REVIEWS'
const LAST_ORDERED = 'LAST_ORDERED'
const SAME_DAY = 'SAME_DAY'
const LIMIT = 9

// Note: SuggestedConcepts uses concepts cache
class SuggestedConcepts extends Component {
  state = {
    accountIntel: undefined,
    conceptFilter: 'High to Low Margin',
    chefs: [],
    cuisineFilter: '',
    cuisineFilters: [],
    dietaryTagFilter: '',
    dietaryTagFilters: [],
    notDietaryTagFilters: [],
    estimatedPricePerPersonFilter: '',
    estimatedPricePerPersonFilters: [],
    concepts: [],
    search: '',
    selectedChef: undefined,
    showIntel: false,
    loadingConcepts: false,
    loadingIntel: false,
    chefCardSection: {}, // { conceptId: SECTION_NAME }
    page: 1,
    totalConcepts: 0,
  }

  componentDidMount() {
    this.refreshConcepts()
  }

  refreshConcepts = async () => {
    const { clientSetUpTime, orderType, headquarterId, loadCachedConcepts } =
      this.props

    const {
      cuisineFilter,
      conceptFilter,
      dietaryTagFilter: dietaryTagFilterValue,
      estimatedPricePerPersonFilter,
      search,
      selectedChef,
      page,
    } = this.state

    this.setState({ loadingConcepts: true })

    let offset = ((page < 1 ? 1 : page) - 1) * LIMIT

    if (offset < this.totalConcepts) {
      offset = this.totalConcepts
    }

    const isNotDietFilter = dietaryTagFilterValue.startsWith('NOT')
    const dietaryTagFilter = isNotDietFilter ? '' : dietaryTagFilterValue
    const neDietaryTagFilter = isNotDietFilter
      ? dietaryTagFilterValue.replace('NOT ', '')
      : ''

    const conceptsRequest = {
      clientSetUpTime,
      headquarterId,
      orderType,
      filters: {
        cuisineFilter,
        dietaryTagFilter,
        neDietaryTagFilter,
        estimatedPricePerPersonFilter,
      },
      search,
      limit: LIMIT,
      offset,
    }
    if (selectedChef) {
      conceptsRequest.chefId = selectedChef.id
    }

    if (conceptFilter && conceptFiltersMap[conceptFilter]) {
      conceptsRequest.sortBy = conceptFiltersMap[conceptFilter].sortBy
      conceptsRequest.sortOrder = conceptFiltersMap[conceptFilter].sortOrder
    }

    const filteredConcepts = await loadCachedConcepts(conceptsRequest)

    const { chefs, concepts, filters, totalConcepts } = filteredConcepts || {}

    const {
      cuisineFilters,
      estimatedPricePerPersonFilters,
      dietaryTagFilters,
      notDietaryTagFilters,
    } = filters || {}

    this.setState({
      chefs,
      concepts,
      cuisineFilters,
      estimatedPricePerPersonFilters,
      dietaryTagFilters,
      notDietaryTagFilters,
      loadingConcepts: false,
      totalConcepts,
    })
  }

  buildCuisineFilters = (concepts) => {
    const filters = {}
    concepts.forEach((concept) => {
      const { tagsCuisine = [] } = concept
      tagsCuisine.forEach((cuisine) => {
        if (!filters[cuisine]) {
          filters[cuisine] = 1
        } else {
          filters[cuisine] += 1
        }
      })
    })
    const counts = Object.entries(filters).sort(
      ([, aCount], [, bCount]) => bCount - aCount,
    )

    return counts.map(([cuisine, count]) => {
      return { text: `${cuisine} (${count})`, value: cuisine }
    })
  }

  onSelectConceptFilter = (e) =>
    this.setState(
      { conceptFilter: e.target.value, page: 1 },
      this.refreshConcepts,
    )

  onSelectCuisineFilter = (e) =>
    this.setState(
      { cuisineFilter: e.target.value, page: 1 },
      this.refreshConcepts,
    )

  onSelectEstimatedPricePerPersonFilter = (e) =>
    this.setState(
      { estimatedPricePerPersonFilter: e.target.value, page: 1 },
      this.refreshConcepts,
    )

  onSelectDietaryTagFilter = (filter) =>
    this.setState(
      { dietaryTagFilter: filter.value, page: 1 },
      this.refreshConcepts,
    )

  loadDietFilters = async (searchOptions) => {
    const { search } = searchOptions
    const searchRegExp = new RegExp(search || '', 'i')
    const matchedFilters = this.allDietFilters().filter((dietaryFilter) => {
      return dietaryFilter.value
        ? searchRegExp.test(dietaryFilter.value)
        : false
    })
    const formattedFilters = matchedFilters.map((dietaryFilter) => ({
      ...dietaryFilter,
      id: dietaryFilter.value,
      title: `${dietaryFilter.value} (${dietaryFilter.count})`,
    }))

    return formattedFilters
  }

  clearDietFilter = () => {
    this.setState({ dietaryTagFilter: '' }, this.refreshConcepts)
  }

  allDietFilters = () => {
    const { dietaryTagFilters, notDietaryTagFilters } = this.state
    const allDietFilters = [
      ...(dietaryTagFilters || []),
      ...(notDietaryTagFilters || []),
    ].sort((a, b) => {
      const aClean = a.value.replace('NOT ', '')
      const bClean = b.value.replace('NOT ', '')
      if (aClean < bClean) {
        return -1
      } else if (aClean > bClean) {
        return 1
      } else {
        return 0
      }
    })

    return allDietFilters
  }

  loadAccountIntel = async () => {
    const { accountIntel } = this.state
    const { account } = this.props

    this.setState({ showIntel: true })
    if (account && account.id !== (accountIntel && accountIntel.id)) {
      this.setState({ loadingIntel: true })
      const accountIntel = await this.props.loadAccountIntel(account.id)
      this.setState({ accountIntel, loadingIntel: false })
    }
  }

  conceptColor = (concept) => {
    const { newOrder, headCount: orderHeadCount, setUpStyle } = this.props
    const { headCount, maxBuffet, maxIndividual } = concept
    const adjHeadCount = newOrder ? headCount + orderHeadCount : headCount
    const maxHeadCount =
      setUpStyle == 'Indidual Boxed Lunches' ? maxIndividual : maxBuffet
    let color
    if (adjHeadCount < maxHeadCount) {
      color = '#48bb78' // green
    } else if (headCount >= maxHeadCount) {
      color = '#f56565' // red
    } else {
      color = '#ecc94b' //yellow
    }

    return color
  }

  renderAccountIntel = () => {
    const { accountIntel = {}, loadingIntel } = this.state
    const { account } = this.props
    const { favoriteChef, favoriteCuisine, lastOrder, allergens, budget } =
      accountIntel

    if (loadingIntel) {
      return (
        <LoadContainer>
          <LoadingIndicator />
        </LoadContainer>
      )
    }

    return (
      <div className="z-10 bg-blue-600 l-150 p-3 py-5 absolute w-64 rounded">
        <p className="text-center text-white text-xl bold truncate md:overflow-clip">
          {account ? account.name : 'Select account first'}
        </p>
        {account && accountIntel && (
          <div className="mt-3">
            <p className="text-base text-white">
              <span className="bold text-white mb-1">Favorite Chef: </span>
              {favoriteChef}
            </p>
            <p className="text-base text-white">
              <span className="bold text-white mb-1">Favorite Cuisine: </span>
              {favoriteCuisine}
            </p>
            <p className="text-base text-white">
              <span className="bold text-white mb-1">Last Order: </span>
              {lastOrder}
            </p>
            <p className="text-base text-white">
              <span className="bold text-white mb-1">Allergens: </span>
              {allergens}
            </p>
            <p className="text-base text-white">
              <span className="bold text-white mb-1">Budget: </span>
              {budget}
            </p>
          </div>
        )}
      </div>
    )
  }

  onClickChefCardTab = (conceptId, section) => {
    const { chefCardSection } = this.state
    this.setState({
      chefCardSection: {
        ...chefCardSection,
        [conceptId]: section,
      },
    })
  }

  renderMenuPreview = (concept) => {
    const { mainMenuItems } = concept

    return (
      <MenuPreview>
        <p className="heading">Mains</p>
        <YSpacing height="5px" />
        {mainMenuItems &&
          mainMenuItems.map((menuItem, index) => {
            return (
              <div key={index}>
                <p className="item-name">{menuItem.name}</p>
                <p className="item-description">
                  {menuItem.description
                    ? menuItem.description.slice(0, 20)
                    : ''}
                  ...
                </p>
                <YSpacing height="10px" />
              </div>
            )
          })}
      </MenuPreview>
    )
  }

  renderChefCardDetails = (concept) => {
    const { chefCardSection } = this.state
    const section = chefCardSection[concept.id]
    if (section === MENU_PREVIEW) {
      return this.renderMenuPreview(concept)
    } else if (section === REVIEWS) {
      return <p>Reviews</p>
    } else if (section === LAST_ORDERED) {
      return <p>Last Ordered</p>
    } else if (section === SAME_DAY) {
      return <p>Same day</p>
    }

    return this.renderMenuPreview(concept)
  }

  loadChefs = async (searchOptions) => {
    const { chefs = [], selectedChef } = this.state

    const { search } = searchOptions

    if (!search && selectedChef) {
      this.clearChefFilter()
    }
    const searchRegExp = new RegExp(search || '', 'i')

    return chefs.filter((chef) => {
      return chef.name ? searchRegExp.test(chef.name) : false
    })
  }

  clearChefFilter = () => {
    this.setState({ selectedChef: undefined }, this.refreshConcepts)
  }

  onSelectChef = (chef) => {
    this.setState({ selectedChef: chef }, this.refreshConcepts)
  }

  onViewMenu = (concept) => {
    ;(async (concept) => {
      const { onSelectConcept, onShowMenuDetails } = this.props

      await onSelectConcept(concept)
      onShowMenuDetails(MENU_DETAILS_SECTION_MODE_VIEW)
    })(concept)
  }

  onSelectMenu = (concept) => {
    ;(async (concept) => {
      const {
        getConceptEstPricePerPerson,
        headCount,
        onSelectConcept,
        onShowMenuDetails,
        prePopulateMenu,
        onAddConceptToOrder,
        navigateToNextSection,
      } = this.props

      const fullConcept = await onSelectConcept(concept)

      const {
        id,
        name,
        chefId,
        isUpdatedStructure,
        conceptsMenuItems,
        tagsCuisineList,
      } = fullConcept || {}

      const isBYO = tagsCuisineList
        ? tagsCuisineList.includes(BUILD_YOUR_OWN)
        : false
      const mains = conceptsMenuItems
        ? pGetMainMenuItems(conceptsMenuItems)
        : []
      const sides = conceptsMenuItems
        ? pGetSideMenuItems(conceptsMenuItems)
        : []
      const mainItemIds = mains.map((item) => item.menuItem.id)
      const sideItemIds = sides.map((item) => item.menuItem.id)
      const pricePerPersonData = await getConceptEstPricePerPerson(
        mainItemIds,
        sideItemIds,
      )

      if (isBYO) {
        const { prePopulatedMains, prePopulatedSides } = prePopulateMenu(
          mains,
          sides,
          isUpdatedStructure,
          isBYO,
          headCount,
          pricePerPersonData,
        )

        const menuItems = [
          ...(prePopulatedMains || []),
          ...(prePopulatedSides || []),
        ]
        await onAddConceptToOrder({
          conceptId: id,
          conceptName: name,
          menuItems,
          chefId,
          isBYO,
        })
        navigateToNextSection()
      } else {
        onShowMenuDetails(MENU_DETAILS_SECTION_MODE_SELECT)
      }
    })(concept)
  }

  render() {
    const { errors } = this.props
    const {
      concepts = [],
      conceptFilter,
      cuisineFilter,
      cuisineFilters,
      dietaryTagFilter,
      estimatedPricePerPersonFilter,
      estimatedPricePerPersonFilters,
      loadingConcepts,
      selectedChef,
      page,
      totalConcepts,
    } = this.state

    const maxPages = totalConcepts ? Math.ceil(totalConcepts / LIMIT) : null

    return (
      <div>
        <FlexContainer
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FlexContainer flexDirection="column">
            <FlexContainer flexDirection="row">
              <AutocompleteInput
                label="Filter by Chef or Restaurant Name"
                width="31%"
                error={errors.chef}
                loaderFunction={this.loadChefs}
                value={selectedChef ? selectedChef.name : ''}
                placeholder="All Chefs / Vendors"
                onSelect={this.onSelectChef}
                onClear={this.clearChefFilter}
                clearOnFocus={false}
              />
              <XSpacing width="20px" />
              <Dropdown
                label="Filter by Price Per Head"
                width="31%"
                value={estimatedPricePerPersonFilter}
                onChange={this.onSelectEstimatedPricePerPersonFilter}
                marginBottom="10px"
              >
                <option value="" selected disabled hidden>
                  Estimated Price Per Person
                </option>
                <option value="">All</option>
                {estimatedPricePerPersonFilters &&
                  estimatedPricePerPersonFilters.map((s) => (
                    <option
                      key={s.key}
                      value={s.key}
                    >{`${s.value} (${s.count})`}</option>
                  ))}
              </Dropdown>
              <XSpacing width="20px" />
              <Dropdown
                label="Sort By"
                width="31%"
                value={conceptFilter}
                onChange={this.onSelectConceptFilter}
                marginBottom="10px"
              >
                <option value="" selected disabled hidden>
                  Select Chef/Menu Filter
                </option>
                {Object.keys(conceptFiltersMap).map((s) => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
              </Dropdown>
            </FlexContainer>

            <YSpacing width="30px" />

            <FlexContainer flexDirection="row">
              <Dropdown
                label="Filter by Cuisine Type"
                width="31%"
                value={cuisineFilter}
                onChange={this.onSelectCuisineFilter}
                marginBottom="10px"
              >
                <option value="" selected disabled hidden>
                  Cuisine Type
                </option>
                <option value="">All</option>
                {cuisineFilters &&
                  cuisineFilters.map((s) => (
                    <option
                      key={s.key}
                      value={s.key}
                    >{`${s.value} (${s.count})`}</option>
                  ))}
              </Dropdown>
              <XSpacing width="20px" />
              <AutocompleteInput
                label="Filter by Dietary Tags"
                displayAttribute="title"
                width="31%"
                loaderFunction={this.loadDietFilters}
                value={dietaryTagFilter}
                placeholder="All Dietary Tags"
                onSelect={this.onSelectDietaryTagFilter}
                onClear={this.clearDietFilter}
                clearOnFocus={false}
              />
              <XSpacing width="20px" />
              {/* TODO: Uncomment for MBP4-2 */}
              {/* <FlexContainer flexDirection='column'>
                <Checkbox
                  label="Show Only Menus Booked on the Same Day"
                  value="sameDay"
                  checked={ false }
                  onChange={ () => {} }
                />
                <YSpacing width="20px" />
                <Checkbox
                  label="Show Only Client's Past Orders"
                  value="pastOrders"
                  checked={ false }
                  onChange={ () => {} }
                />
              </FlexContainer> */}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="15px" />
        {loadingConcepts && <LoadingIndicator />}
        <ChefCardContainer>
          {!loadingConcepts &&
            concepts.map((concept) => {
              const { account } = this.props
              const {
                id,
                estimatedPricePerPerson,
                chefName,
                margin,
                name,
                tagsCuisine,
              } = concept
              const marginRound = Math.round(margin || 0)
              const marginColor = marginRound >= 45 ? 'green' : 'red'
              const estimatedPricePerPersonColor =
                account &&
                account.avgPricePerPerson &&
                estimatedPricePerPerson > parseFloat(account.avgPricePerPerson)
                  ? 'red'
                  : 'green'
              const cuisines = tagsCuisine.join(', ')

              return (
                <ChefCard key={id}>
                  <FlexContainer flexDirection="column" alignItems="flex-end">
                    {/* Top Section */}
                    <FlexContainer>
                      <div className="card-labels1">
                        <FlexContainer flexDirection="column">
                          <p className="card-chef-name">{`Chef ${chefName}'s ${name}`}</p>
                          <p className="card-headcount">{`Cusine(s): ${cuisines}`}</p>
                        </FlexContainer>
                      </div>
                      <div className="card-labels2">
                        <FlexContainer flexDirection="column">
                          <FlexContainer
                            flexDirection="row"
                            justifyContent="flex-end"
                          >
                            <BiDollar />
                            <BiUser />
                            <XSpacing width="5px" />
                            <p style={{ color: estimatedPricePerPersonColor }}>
                              ${estimatedPricePerPerson}
                            </p>
                          </FlexContainer>
                          {/* TODO: Uncomment for MBP4-2 */}
                          {/* <FlexContainer flexDirection='row' justifyContent='flex-end'>
                          <TbChefHat />
                          <TbArrowRight />
                          <TbBuilding />
                          <XSpacing width="5px" />
                          <p style={ { color: 'green' } }>{ 10 } mi</p>
                        </FlexContainer> */}
                          <FlexContainer
                            flexDirection="row"
                            justifyContent="flex-end"
                          >
                            <p style={{ color: 'gray' }}>Food Margin</p>
                            <XSpacing width="5px" />
                            <p style={{ color: marginColor }}>{marginRound}%</p>
                          </FlexContainer>
                        </FlexContainer>
                      </div>
                    </FlexContainer>

                    <YSpacing height="15px" />
                    {/* Tabs */}
                    <FlexContainer
                      flexWrap="wrap"
                      justifyContent="space-between"
                    >
                      {/*<LinkText
                      fontSize="10px"
                      onClick={ () => this.onClickChefCardTab( id, MENU_PREVIEW ) }
                      label="Menu Preview"
                      textDecoration={ MENU_PREVIEW === cardSection || cardSection === undefined ? 'underline' : 'none' }
                      />*/}
                      {/* TODO: Uncomment for MBP4-2 */}
                      {/* <LinkText
                      fontSize="10px"
                      onClick={ () => this.onClickChefCardTab( id, REVIEWS ) }
                      label="Reviews"
                      textDecoration={ REVIEWS === cardSection ? 'underline' : 'none' }
                    />
                    <LinkText
                      fontSize="10px"
                      onClick={ () => this.onClickChefCardTab( id, LAST_ORDERED ) }
                      label="Last Ordered"
                      textDecoration={ LAST_ORDERED === cardSection ? 'underline' : 'none' }
                    />
                    <LinkText
                      fontSize="10px"
                      onClick={ () => this.onClickChefCardTab( id, SAME_DAY ) }
                      label="# Booked Same Day (3)"
                      textDecoration={ SAME_DAY === cardSection ? 'underline' : 'none' }
                    /> */}
                    </FlexContainer>
                    <YSpacing height="10px" />
                    <FlexContainer height="200px" overflow="auto">
                      {this.renderChefCardDetails(concept)}
                    </FlexContainer>
                    <FlexContainer
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <LinkText
                        fontSize="10px"
                        onClick={() => this.onViewMenu(concept)}
                        label="View Menu"
                        textDecoration={'underline'}
                      />
                      <XSpacing width="5px" />
                      <LinkText
                        fontSize="10px"
                        onClick={() => this.onSelectMenu(concept)}
                        label="Select Menu"
                        textDecoration={'underline'}
                      />
                    </FlexContainer>
                  </FlexContainer>

                  {/* <p className="card-chef-name">{ `Chef ${ chefName }' ${ name }` }</p>
                <p className="cuisine">{ cuisines }</p>
                <p className="card-headcount"><span style={ { backgroundColor: color } } />{ headCount } Headcount</p> */}
                </ChefCard>
              )
            })}
        </ChefCardContainer>
        <FlexContainer justifyContent="flex-end">
          <Button
            disabled={page <= 1}
            testId="navigate-concepts-page-left"
            label="〈"
            onClick={() => {
              if (page > 1) {
                this.setState({ page: page - 1 }, this.refreshConcepts)
              }
            }}
          />
          <XSpacing width="10px" />
          {maxPages && `${page}/${maxPages}`}
          <XSpacing width="10px" />
          <Button
            disabled={page + 1 > maxPages}
            testId="navigate-concepts-page-right"
            label="〉"
            onClick={() => {
              if (page + 1 <= maxPages) {
                this.setState({ page: page + 1 }, this.refreshConcepts)
              }
            }}
          />
        </FlexContainer>
      </div>
    )
  }
}

const ChefCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ChefCard = styled.div`
  width: 32%;
  background-color: #f0f6ff;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
  .card-chef-name {
    font-family: 'bold';
  }
  .card-headcount {
    font-family: 'light';
    font-size: 12px;
  }
  .card-labels1 {
    width: 55%;
  }
  .card-labels2 {
    width: 45%;
    p {
      font-family: 'bold';
      font-size: 12px;
      color: ${(props) => (props.color ? props.color : '#000')};
    }
  }
  span {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
    display: inline-block;
  }
  &:hover {
    box-shadow:
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`

const LoadContainer = styled.div`
  position: absolute;
  right: -150px;
  top: -70px;
`

const MenuPreview = styled.div`
  flex-direction: column;
  .heading {
    font-family: 'bold';
    font-size: 15px;
  }
  .item-name {
    font-family: 'bold';
    font-size: 15px;
  }
  .item-description {
    font-family: 'light';
    font-size: 12px;
  }
`

SuggestedConcepts.propTypes = {
  account: PropTypes.object,
  clientSetUpTime: PropTypes.instanceOf(Moment),
  headquarterId: PropTypes.string,
  headCount: PropTypes.number,
  orderType: PropTypes.string,
  orderableType: PropTypes.string,
  orderableId: PropTypes.string,
  newOrder: PropTypes.bool,
  setUpStyle: PropTypes.string,
  errors: PropTypes.object,

  getConceptEstPricePerPerson: PropTypes.func,
  showMenuDetails: PropTypes.func,
  loadAccountIntel: PropTypes.func,
  loadChefs: PropTypes.func,
  loadCachedConcepts: PropTypes.func,
  loadChefSuggestions: PropTypes.func,
  loadProfileSuggestions: PropTypes.func,
  onSelectSuggestedConcept: PropTypes.func,
  onAddConceptToOrder: PropTypes.func,
  onSelectConcept: PropTypes.func,
  onShowMenuDetails: PropTypes.func,
  onSelectChef: PropTypes.func,
  pCalculateConceptMargin: PropTypes.func,
  pCalculateConceptPPP: PropTypes.func,
  prePopulateMenu: PropTypes.func,
  navigateToNextSection: PropTypes.func,
  pGetMainMenuItems: PropTypes.func,
  pGetSideMenuItems: PropTypes.func,
}

export default SuggestedConcepts
