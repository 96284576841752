import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TechHelpForm } from '@components/common'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import { FlexContainer, XSpacing, YSpacing } from '@components/common'
import { SuggestedConcepts } from '@containers/order/edit/sections'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import { colors, MENU_DETAILS_SECTION_MODE_VIEW } from '../../../../constants'
import Tooltip from '@components/common/Tooltip'
import { BiDollar, BiUser } from 'react-icons/bi'
import { MenuDetailsModal } from '@components/order/edit'
import { OrderablePropType } from './propTypes'

const EditOrderSection2 = (props) => {
  const {
    errors,
    orderable,
    previousSection,
    nextSection,
    headquarterId,

    onHide,
    onAddConceptToOrder,
    onSelectSuggestedConcept,
    onClearSelectedConcept,
    onSelectConcept,
    onSelectChef,
    navigateToSection,
  } = props

  const {
    account,
    selectedConcept,
    clientSetUpTime,
    headCount,
    id,
    isVCX,
    orderableType,
    orderType,
    setUpStyle,
  } = orderable

  const [showMenuDetailsState, setShowMenuDetailsState] = useState({
    show: false,
    mode: MENU_DETAILS_SECTION_MODE_VIEW,
  }) // Modes 'view' and 'select'

  const isSnackPack = orderType === 'Snack Pack'

  const navigateToPreviousSection = () => {
    navigateToSection(previousSection)
  }

  const navigateToNextSection = () => {
    navigateToSection(nextSection)
  }

  const showMenuDetails = (mode) => {
    setShowMenuDetailsState({ show: true, mode })
  }

  const closeMenuDetails = () => {
    onClearSelectedConcept()
    setShowMenuDetailsState({
      show: false,
      mode: MENU_DETAILS_SECTION_MODE_VIEW,
    })
  }

  const renderSuggestedConcepts = () => {
    if (!account) {
      return <div>Please select an account to see available chefs</div>
    } else if (!clientSetUpTime && !isVCX && !isSnackPack) {
      return <div>Please select a delivery date to see available chefs</div>
    } else if (!headCount) {
      return <div>Please select head count to see available chefs</div>
    } else {
      return (
        <SuggestedConcepts
          errors={errors}
          newOrder={!id}
          account={account}
          clientSetUpTime={clientSetUpTime}
          headCount={headCount}
          setUpStyle={setUpStyle}
          headquarterId={headquarterId}
          orderType={orderType}
          orderableType={orderableType}
          orderableId={id}
          onSelectSuggestedConcept={onSelectSuggestedConcept}
          onAddConceptToOrder={onAddConceptToOrder}
          onSelectConcept={onSelectConcept}
          onSelectChef={onSelectChef}
          navigateToNextSection={navigateToNextSection}
          onShowMenuDetails={showMenuDetails}
        />
      )
    }
  }

  const renderToolTip = () => {
    return (
      <FlexContainer flexDirection="row" justifyContent="space-between">
        <FlexContainer flexDirection="row" width="5%">
          <BiDollar />
          <BiUser />
        </FlexContainer>
        <FlexContainer flexDirection="column" width="93%">
          <div style={{ fontSize: '16px' }}>
            <p style={{ color: colors.gray400 }}>
              *All price per head (pph) pricing is an estimated menu price per
              person based on suggested catering order amounts,{' '}
              <b>including a buffer</b>, with the following breakdown: 1st
              protein (50%), 2nd protein (50%), 1 vegetable entree (20%),1
              starch side (75%), 1 veggie side (75%)
            </p>
            <p style={{ color: colors.gray400 }}> </p>
            <p style={{ color: colors.gray400, marginTop: '20px' }}>
              <b>40 Headcount Example:</b>
            </p>
            <p style={{ color: colors.gray400 }}>
              {'Entree 1: BBQ Beef Brisket- $8.25'}
            </p>
            <p style={{ color: colors.gray400 }}>
              {'Entree 2: Beef Curry- $10.95'}
            </p>
            <p style={{ color: colors.gray400 }}>
              {'Entree 3: Tofu Curry- $7.50'}
            </p>
            <p style={{ color: colors.gray400 }}>{'Starch Side: $4.75'}</p>
            <p style={{ color: colors.gray400 }}>{'Vegetable Side: $4.85'}</p>
            <p style={{ color: colors.gray400 }}>{''}</p>
            <p style={{ color: colors.gray400, marginTop: '20px' }}>
              {'Algorithm Math:'}
            </p>
            <p style={{ color: colors.gray400 }}>
              [(($8.25x20)+($10.95x20)+($7.50x8))/40]+[(($4.75x30)+($4.85x30))/40]={' '}
              <b>$18.30/pp</b>
            </p>
          </div>
        </FlexContainer>
      </FlexContainer>
    )
  }

  return (
    <div>
      {showMenuDetailsState.show && (
        <MenuDetailsModal
          mode={showMenuDetailsState.mode}
          account={account}
          concept={selectedConcept}
          headCount={headCount}
          onAddConceptToOrder={onAddConceptToOrder}
          onShowMenuDetails={showMenuDetails}
          closeMenuDetails={closeMenuDetails}
          navigateToNextSection={navigateToNextSection}
        />
      )}
      <OrderFormSectionTitle>
        <span>3</span>Search Chefs or Restaurants
        <Tooltip
          width="400px"
          isTooltipBottom={true}
          iconType="info"
          margin="0 0 0 10px"
        >
          {renderToolTip()}
        </Tooltip>
      </OrderFormSectionTitle>
      <YSpacing height="20px" />
      {renderSuggestedConcepts()}
      <YSpacing height="20px" />
      <FlexContainer alignItems="center" justifyContent="flex-end">
        <Button
          testId="update-order-details"
          label={'〈 Update order details'}
          onClick={navigateToPreviousSection}
        />
        <XSpacing width="20px" />
        <Button
          testId="skip"
          label={'Skip to add service or custom items only 〉'}
          onClick={navigateToNextSection}
          backgroundColor={colors.orange}
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer alignItems="center" justifyContent="flex-end">
        <TechHelpForm margin="0 30px 0 0" />
        <LinkText
          testId="cancel"
          label="Cancel"
          onClick={onHide}
          color={colors.violet}
        />
      </FlexContainer>
    </div>
  )
}

EditOrderSection2.propTypes = {
  orderable: OrderablePropType,
  previousSection: PropTypes.string,
  nextSection: PropTypes.string,
  headquarterId: PropTypes.number,
  errors: PropTypes.object,

  onHide: PropTypes.func,
  onAddConceptToOrder: PropTypes.func,
  onSelectChef: PropTypes.func,
  onSelectSuggestedConcept: PropTypes.func,
  onSelectConcept: PropTypes.func,
  onClearSelectedConcept: PropTypes.func,
  navigateToSection: PropTypes.func,
}

export default EditOrderSection2
