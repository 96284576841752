import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Label } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'

interface Option {
  value: string
  label: string
}

interface MultiSelectDropdownProps {
  label: string
  includeSelectAll: boolean
  options: Option[]
  checkedOptions: string[]
  onChange: (selected: string[]) => void

  width: string
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  label,
  includeSelectAll,
  options,
  checkedOptions,
  onChange,

  width,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

  useEffect(() => {
    setSelectedOptions(checkedOptions)
  }, [checkedOptions])

  const handleCheckboxChange = (optionValue: string) => {
    const newSelectedOptions = selectedOptions.includes(optionValue)
      ? selectedOptions.filter((item) => item !== optionValue)
      : [...selectedOptions, optionValue]

    setSelectedOptions(newSelectedOptions)
    onChange(newSelectedOptions)
  }

  const handleSelectAllChange = () => {
    const newSelectedOptions =
      selectedOptions.length === options.length
        ? []
        : options.map((option) => option.value)

    setSelectedOptions(newSelectedOptions)
    onChange(newSelectedOptions)
  }

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  return (
    <FlexContainer flexDirection="column" width={width}>
      <Label>{label}</Label>
      <DropdownContainer>
        <DropdownButton onClick={toggleDropdown}>
          <span>{isDropdownVisible ? '▲' : '▼'}</span>
        </DropdownButton>
        <DropdownContent isVisible={isDropdownVisible}>
          {includeSelectAll && (
            <CheckboxLabel>
              <input
                type="checkbox"
                checked={selectedOptions.length === options.length}
                onChange={handleSelectAllChange}
              />
              Select All
            </CheckboxLabel>
          )}
          {options.map((option) => (
            <CheckboxLabel key={option.value}>
              <input
                type="checkbox"
                value={option.value}
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              {option.label}
            </CheckboxLabel>
          ))}
        </DropdownContent>
      </DropdownContainer>
    </FlexContainer>
  )
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: white;
  color: #3c434e;
  line-height: 1.4;
  width: 100%;
`

const DropdownButton = styled.button`
  background-color: white;
  color: #3c434e;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid gray;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
  min-width: 160px;
  line-height: 1.4;
  width: 100%;
  &:hover {
    border-color: #999;
  }
`

const DropdownContent = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: absolute;
  background-color: #3c434e;
  min-width: 160px;
  min-height: 250px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 4px;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: white;

  &:hover {
    background-color: #5496fb;
  }

  input {
    margin-right: 8px;
  }

  input&:hover {
    background-color: #5496fb;
  }
`

export default MultiSelectDropdown
