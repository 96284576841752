import React from 'react'
import PropTypes from 'prop-types'

const MenuItem = (props) => {
  const { menuItem, onSelect } = props
  const dietaryTags = menuItem.tagsDietaryPreferenceList.split(',')

  return (
    <div
      className="hover:bg-blue-100 cursor-pointer flex justify-between py-2"
      onClick={() => onSelect(menuItem)}
    >
      <div>
        <p className="text-base">{menuItem.name}</p>
        {dietaryTags.map((tag, i, arr) =>
          arr.length - 1 === i ? (
            <span key={i} className={`dietary-tag ${tag} `}>
              {tag}
            </span>
          ) : (
            <span key={i}>
              <span className={`dietary-tag ${tag}`}>{tag}</span> •{' '}
            </span>
          ),
        )}
      </div>
      {menuItem && menuItem.price && <div> ${menuItem.price}</div>}
    </div>
  )
}

MenuItem.propTypes = {
  className: PropTypes.string,
  menuItem: PropTypes.object,

  onSelect: PropTypes.func,
}

MenuItem.defaultProps = {
  onSelect: () => undefined,
}

export default MenuItem
