import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

const ConfirmationModal = (props) => {
  const onHide = (e) => {
    e.stopPropagation()
    e.preventDefault()

    props.close()
  }

  const onConfirm = (e) => {
    e.stopPropagation()
    e.preventDefault()

    props.confirm()
  }

  const { show, text, title } = props

  return (
    <Modal show={show} onHide={onHide} backdrop={false} className="alert-modal">
      <Modal.Header closeButton>
        <Modal.Title> {title} </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="confirmation-text">{text}</p>
      </Modal.Body>

      <Modal.Footer>
        <button
          className="button-alert"
          style={{ marginRight: '10px' }}
          onClick={onHide}
        >
          {' '}
          No{' '}
        </button>
        <button className="button-primary" onClick={onConfirm}>
          {' '}
          Yes{' '}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,

  close: PropTypes.func,
  confirm: PropTypes.func,
}

export default ConfirmationModal
