import React from 'react'
import PropTypes from 'prop-types'
import { LabelInfo } from '@components/common/form'
import YSpacing from '@components/common/YSpacing'
import FlexContainer from '@components/common/FlexContainer'
import Panel from '@components/common/Panel'
import { PanelListItem } from '@res/styledComponents/index'

import { moneyString } from '~/utils'
import { NetPaymentDays, AutoBatchPeriods } from '../../../../constants'

const AccountProfileListGroup = (props) => {
  if (!props.accountProfile) {
    return null
  }

  const {
    addresses,
    budgetPerPerson,
    callOnArrival,
    contacts,
    defaultBillingAddress,
    estimatedMonthlyValue,
    isTaxExempt,
    parentAccount,
    name,
    needsServingUtensils,
    needsUtensils,
    numEmployees,
    paymentMethods,
    pin,
    netPaymentDays,
    autoBatchPeriod,
    vendorId,
    totalMealsDonated,
    totalCarbonNeutralContribution,
    treesPlanted,
  } = props.accountProfile

  const parentAccountName = parentAccount && parentAccount.name

  return (
    <div>
      <Panel width="100%" maxWidth="400px" heading="Account Details">
        <FlexContainer justifyContent="space-between">
          <LabelInfo label="Account Name" value={name} width="48%" />
          <LabelInfo label="Account Pin" value={pin} width="48%" />
        </FlexContainer>
        <YSpacing height="20px" />
        {parentAccountName && (
          <LabelInfo
            label="Parent Account Name"
            value={parentAccountName}
            width="100%"
            testId="parent-account"
          />
        )}
        {parentAccountName && <YSpacing height="20px" />}
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Tax Exempt"
            testId="vendor-id"
            value={isTaxExempt ? 'Yes' : 'No'}
            width="48%"
          />
          <LabelInfo
            label="Call On Arrival"
            testId="call-on-arrival"
            value={callOnArrival ? 'Yes' : 'No'}
            width="48%"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="# of Employees"
            testId="number-of-employees"
            value={numEmployees || 'N/A'}
            width="48%"
          />
          <LabelInfo
            label="Budget Per Person (excluding tax)"
            testId="budget-per-person"
            value={`$${moneyString(Number(budgetPerPerson))}`}
            width="48%"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Needs Utensils"
            value={needsUtensils ? 'Yes' : 'No'}
            testId="needs-utensils"
            width="48%"
          />
          <LabelInfo
            label="Needs Utensils, Napkins, & Plates"
            value={needsServingUtensils ? 'Yes' : 'No'}
            testId="needs-utensils-and-napkins"
            width="48%"
          />
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Estimated Monthly Value"
            value={`$${moneyString(Number(estimatedMonthlyValue))}`}
            testId="estimated-monthly-value"
            width="48%"
          />
          <LabelInfo
            label="Total # of Meals Donated"
            value={totalMealsDonated}
            testId="total-meals-donated"
            width="48%"
          />
          {vendorId && (
            <LabelInfo
              label="Vendor ID"
              testId="vendor-id"
              value={vendorId}
              width="48%"
            />
          )}
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Total $ Contributed to Carbon Neutral"
            value={`$${moneyString(
              Number(totalCarbonNeutralContribution || 0),
            )}`}
            testId="total-carbon-neutral-contributed"
            width="48%"
          />
          <LabelInfo
            label="Total # of Trees Planted"
            value={`${treesPlanted || 0}`}
            testId="total-trees-planted"
            width="48%"
          />
        </FlexContainer>
      </Panel>
      <Panel
        width="100%"
        maxWidth="400px"
        heading="Net & Recurring Payment Details"
      >
        <FlexContainer justifyContent="space-between">
          <LabelInfo
            label="Net Payment Terms"
            value={NetPaymentDays[netPaymentDays]}
            width="23%"
          />
          <LabelInfo
            label="Invoicing Cadence"
            value={AutoBatchPeriods[autoBatchPeriod]}
            width="73%"
          />
        </FlexContainer>
      </Panel>
      <Panel width="100%" maxWidth="400px" heading="Addresses">
        {addresses &&
          addresses.map(
            (a, i) =>
              a.fullAddress.length > 0 && (
                <PanelListItem
                  isLast={i === addresses.length - 1}
                  id="addresses"
                  key={a.id}
                >
                  {a.fullAddress}
                </PanelListItem>
              ),
          )}
      </Panel>
      <Panel width="100%" maxWidth="400px" heading="Default Billing Address">
        {defaultBillingAddress && defaultBillingAddress.id ? (
          <LabelInfo
            testId="default-billing-address"
            value={defaultBillingAddress.fullAddress}
            width="100%"
          />
        ) : (
          <p>No default selected</p>
        )}
      </Panel>
      <Panel width="100%" maxWidth="400px" heading="Payment Methods">
        {paymentMethods &&
          paymentMethods.map(
            (p, i) =>
              p.shortName.length > 0 && (
                <PanelListItem
                  isLast={i === paymentMethods.length - 1}
                  id="payment-methods"
                  key={p.id}
                >
                  {p.shortName}
                </PanelListItem>
              ),
          )}
      </Panel>
      <Panel width="100%" maxWidth="400px" heading="Contacts">
        {contacts &&
          contacts.map(
            (c, i) =>
              c.formattedDescription.length > 0 && (
                <PanelListItem
                  isLast={i === contacts.length - 1}
                  id="contacts"
                  key={c.id}
                >
                  {c.formattedDescription}
                </PanelListItem>
              ),
          )}
      </Panel>
    </div>
  )
}

AccountProfileListGroup.propTypes = {
  accountProfile: PropTypes.object,
}

AccountProfileListGroup.defaultProps = {
  accountProfile: {},
}

export default AccountProfileListGroup
