import { camelCaseify, formAdd } from '~/utils'

export const pRequestAccountContacts = (accountId) => {
  return {
    account_id: accountId,
    results_per_page: 500,
  }
}

export const pResponseAccountContacts = (json) => {
  return camelCaseify(json)
}

export const pRequestUpdateAccountContact = (data) => {
  const req = {
    account: {},
  }
  req.account.account_contacts_attributes = []
  const account_contact = {
    contact_attributes: {},
  }
  formAdd(data, req.account, 'accountId', 'id')
  formAdd(data, account_contact, 'id', 'id')
  formAdd(data, account_contact, 'role', 'role')
  formAdd(
    data,
    account_contact,
    'cateringReminderEmails',
    'catering_reminder_emails',
  )
  formAdd(data, account_contact, 'invoiceEmails', 'invoice_emails')
  formAdd(data, account_contact, 'receiptEmails', 'receipt_emails')
  formAdd(data, account_contact, 'contactId', 'contact_id')
  formAdd(data, account_contact.contact_attributes, 'contactId', 'id')
  formAdd(data, account_contact.contact_attributes, 'firstName', 'first_name')
  formAdd(data, account_contact.contact_attributes, 'lastName', 'last_name')
  formAdd(data, account_contact.contact_attributes, 'email', 'email')
  formAdd(data, account_contact.contact_attributes, 'phone', 'phone_number')
  req.account.account_contacts_attributes.push(account_contact)

  return req
}
