import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { config } from 'hungry-core2'

import { DateInput, TextInput } from '@components/common/form'

const Colspan = '8'
const Headers = [
  'Number',
  'Menu Concept',
  'Chef',
  'Head Count',
  'Total',
  'Date',
  'Time',
  'Details',
]

export class OrderHistory extends Component {
  state = {
    count: undefined,
    orders: undefined,
    filters: {
      page: 1,
      resultsPerPage: 20,
      search: '',
      accountId: undefined,
      serializer: 'for_account',
      fromDate: undefined,
      toDate: undefined,
    },
  }

  componentDidMount() {
    const { filters } = this.state
    const { accountId } = this.props
    this.setState({ filters: { ...filters, accountId } }, () => {
      this.searchOrders(true)
    })
  }

  delayedSearchOrders = (getCounts) => {
    this.props.callAfterTimeout(() => this.searchOrders(getCounts))
  }

  searchOrders = (getCounts) => {
    this.setState({ orders: undefined }, this.loadOrders)
    if (getCounts) {
      this.getOrderCount()
    }
  }

  getOrderCount = async () => {
    const { filters } = this.state
    const { getOrderCount } = this.props
    const count = await getOrderCount(filters)
    this.setState({ count })
  }

  loadOrders = async () => {
    const { filters } = this.state
    const { searchOrders } = this.props
    const orders = await searchOrders(filters)
    this.setState({ orders })
  }

  setFilter = (newFilters, getCounts = true) => {
    const { filters } = this.state
    this.setState({ filters: { ...filters, ...newFilters } }, () =>
      this.delayedSearchOrders(getCounts),
    )
  }

  renderOrder = (orderable) => {
    const {
      id,
      orderNumber,
      chefName,
      total,
      dateMoment,
      headCount,
      conceptName,
    } = orderable
    const link = `../sales/${id}`

    return (
      <tr key={id}>
        <td>
          <div> {orderNumber}</div>
        </td>
        <td>
          <div> {conceptName}</div>
        </td>
        <td>
          <div> {chefName}</div>
        </td>
        <td>
          <div> {headCount}</div>
        </td>
        <td>
          <div> ${total}</div>
        </td>
        <td>
          <div> {dateMoment.format('MMM Do YYYY')}</div>
        </td>
        <td>
          <div> {dateMoment.format('h:mm:ss a')}</div>
        </td>
        <td>
          <a
            className="order-link"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {orderNumber}
          </a>
        </td>
      </tr>
    )
  }

  render() {
    const { count, filters, orders } = this.state
    const { buildGetRequest } = this.props
    const { resultsPerPage, page, search, fromDate, toDate } = filters
    const csvUrl = buildGetRequest(filters)
    let shown = `${(page - 1) * resultsPerPage + 1}...${
      (page - 1) * resultsPerPage + ((orders && orders.length) || 0)
    }`
    if (orders && orders.length === 0) {
      shown = '0'
    }

    return (
      <table className="table page">
        <thead>
          <tr>
            <th colSpan={Colspan}>
              <h2>Orders History</h2>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={Colspan}>
              <div className="header-inputs-row">
                <div className="flex flex-align-center">
                  <p className="mr-5">Show</p>
                  <DateInput
                    date={fromDate}
                    onChange={(value) =>
                      this.setFilter({ fromDate: value, page: 1 })
                    }
                  />
                </div>
                <div className="flex flex-align-center ml-5">
                  <p className="mr-5">To</p>
                  <DateInput
                    date={toDate}
                    onChange={(value) =>
                      this.setFilter({ toDate: value, page: 1 })
                    }
                  />
                </div>
                <div className="flex flex-align-center ml-15">
                  {orders && (
                    <p className="ml-5 mr-20">
                      Showing {orders ? orders.length : 0} orders {shown} out of{' '}
                      {count == undefined ? '...' : count}
                    </p>
                  )}
                </div>
                <div className="flex">
                  {orders && page > 1 && (
                    <button
                      className="page-arrow"
                      onClick={() => this.setFilter({ page: page - 1 })}
                    >
                      ◄
                    </button>
                  )}
                  <p className="nowrap page-number">Page {page}</p>
                  {orders &&
                    orders.length > 0 &&
                    orders.length === resultsPerPage && (
                      <button
                        className="page-arrow"
                        onClick={() => this.setFilter({ page: page + 1 })}
                      >
                        ►
                      </button>
                    )}
                </div>
                <div className="flex flex-align-center ml-20">
                  <p className="mr-5">Show </p>
                  <select
                    className="page-select"
                    value={resultsPerPage}
                    onChange={(e) =>
                      this.setFilter(
                        {
                          resultsPerPage: parseInt(e.target.value || 20),
                          page: 1,
                        },
                        false,
                      )
                    }
                  >
                    {[20, 50, 100].map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-align-center ml-15 search-input-icon mr-15">
                  <TextInput
                    placeholder="search"
                    value={search}
                    onInput={(e) =>
                      this.setFilter({ search: e.target.value, page: 1 })
                    }
                  />
                </div>
                <div>
                  {orders && orders.length > 0 && (
                    <a
                      className="button-primary"
                      href={`${config.api_host}/api/admin/orders/?csv=true${csvUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Export To CSV
                    </a>
                  )}
                </div>
              </div>
            </th>
          </tr>
          <tr className="promocode-table-headers">
            {Headers.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
          {orders == undefined && (
            <tr>
              <td colSpan={Colspan}>
                <div className="no-promocodes">Loading...</div>
              </td>
            </tr>
          )}
          {orders && orders.length === 0 && (
            <tr>
              <td colSpan={Colspan}>
                <div className="no-promocodes">No results</div>
              </td>
            </tr>
          )}
          {orders && orders.map((order) => this.renderOrder(order))}
        </tbody>
      </table>
    )
  }
}

OrderHistory.propTypes = {
  accountId: PropTypes.string,

  buildGetRequest: PropTypes.func,
  callAfterTimeout: PropTypes.func,
  getOrderCount: PropTypes.func,
  searchOrders: PropTypes.func,
}

export default OrderHistory
