import { BaseService } from 'hungry-core2'
import AccountsReceivable from './accountsReceivable'
import Alert from './alert'
import BatchOrderPage from './batchOrderPage'
import CalcChefProcFeeModal from './calcChefProcFeeModal'
import ConfirmationModal from './confirmation'
import Dashboard from './dashboard'
import { EditAccount, EditAccountContact } from './editAccount'
import EditBatchOrder from './editBatchOrder'
import {
  CopyMenuItems,
  EditChef,
  EditChildChefs,
  EditChefContact,
  EditChildMenuItem,
  EditMenuConcept,
  EditMenuItem,
  EditRecipe,
  EditServiceItem,
  EditVirtualItem,
  EditVirtualKit,
  EditSnackPack,
  DisplayChefFutureOrders,
} from './editChef'
import EditMarket from './editMarket'
import EditOrder from './editOrder'
import EditProposal from './editProposal'
import EditUser from './editUser'
import Errors from './errors'
import FlashMessage from './flashMessages'
import ResultsMessageModal from './resultsMessageModal'
import LoadingIndicator from './loadingIndicator'
import OrderPage from './orderPage'
import ProposalDashboard from './proposalDashboard'
import ProposalPage from './proposalPage'
import Timer from './timer'
import AuditHistory from './auditHistory'

const SubServices = {
  AccountsReceivable,
  Alert,
  AuditHistory,
  BatchOrderPage,
  CalcChefProcFeeModal,
  ConfirmationModal,
  Dashboard,
  EditAccount,
  EditAccountContact,
  EditBatchOrder,
  EditChef,
  EditChildChefs,
  EditChefContact,
  EditChildMenuItem,
  EditMarket,
  EditMenuConcept,
  EditMenuItem,
  EditRecipe,
  EditServiceItem,
  EditOrder,
  EditProposal,
  EditUser,
  EditVirtualItem,
  EditVirtualKit,
  EditSnackPack,
  Errors,
  DisplayChefFutureOrders,
  FlashMessage,
  ResultsMessageModal,
  LoadingIndicator,
  OrderPage,
  ProposalDashboard,
  ProposalPage,
  Timer,
  CopyMenuItems,
}

export default class UIService extends BaseService {
  constructor(dispatch, getState) {
    super(dispatch, getState)

    for (const s in SubServices) {
      this[s] = new SubServices[s](dispatch, getState)
    }
  }
}
