import { connect } from 'react-redux'

import { ManualChargeSection } from '@components/order/orderSections'

import services from '@services'
import coordinators from '@coordinators'
import presenters from '@presenters'
import { HandleError, Validate } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { processing } = state.orderPage
  const chargeClientError = state.errors.chargeClient || ''
  const paymentCreationErrors = state.errors.chargeClientPaymentCreation || {}
  const paymentChangeErrors = state.errors.chargeClientPaymentChange || {}
  const { order, user } = state
  const { account, isPaid, paidAmount, paymentMethod, total, transactions } =
    order
  const { paymentMethods } = account || {}
  const hqId = user && user.lastHeadquarterSignIn
  const hqRoles =
    (user &&
      user.roles.filter((r) => r.headquarterId == hqId).map((r) => r.name)) ||
    []

  let amountDue = total - paidAmount
  amountDue = amountDue >= 0 ? amountDue.toFixed(2) : '0.00'

  return {
    account,
    amountDue,
    hqRoles,
    isPaid,
    order,
    paidAmount,
    paymentMethod,
    paymentMethods,
    processing,
    transactions,

    chargeClientError,
    paymentCreationErrors,
    paymentChangeErrors,
  }
}

const mapDispatchToProps = () => {
  const { SaveAdhocPaymentMethod, UpdateOrderPaymentMethod } = coordinators
  const { OrderService, RestService, UIService } = services
  const { pFindIdenticalPaymentMethod } = presenters
  const {
    pRequestPayOrderWithExternal,
    pRequestSaveAdhocPaymentMethod,
    pRequestUpdateOrderPaymentMethod,
    pResponseGeneric,
    pResponseOrder,
    pResponseOrderError,
  } = presenters.Api
  const { vChargeOrder, vPaymentMethod } = presenters.Validation
  const { clear: clearErrors, clearSingle } = UIService.Errors
  const clearError = clearSingle('manualCharge')

  const updateOrderPaymentMethod = UpdateOrderPaymentMethod({
    OrderService,
    RestService,
    pRequestUpdateOrderPaymentMethod,
    pResponseOrder,
    HandleError,
  })
  const savePaymentMethod = SaveAdhocPaymentMethod({
    RestService,
    pFindIdenticalPaymentMethod,
    pRequestSaveAdhocPaymentMethod,
    pResponseGeneric,
    HandleError,
  })
  const chargeClient = coordinators.ChargeClient({
    HandleError,
    OrderService,
    RestService,
    UIService,
    pRequestPayOrderWithExternal,
    pResponseOrder,
    pResponseOrderError,
  })
  const displayAlert = ({ message, title }) =>
    UIService.Alert.showPopup({ message, title })
  const validateOrder = Validate({
    validator: vChargeOrder,
    namespace: 'chargeClientPaymentChange',
  })
  const validatePaymentMethod = Validate({
    validator: vPaymentMethod,
    namespace: 'chargeClientPaymentCreation',
  })

  return {
    chargeClient,
    displayAlert,
    savePaymentMethod,
    validateOrder,
    validatePaymentMethod,
    updateOrderPaymentMethod,

    clearError,
    clearErrors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualChargeSection)
