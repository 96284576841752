import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import NavBar from '@components/common/NavBar'

import services from '@services'
import presenters from '@presenters'
import coordinators from '@coordinators'
import { HandleError } from '@coordinators/composed'

const mapStateToProps = (state) => {
  const { id, lastLoggedInHq, firstName } = state.user
  const {
    headquarter: { userHeadquarters },
  } = state

  return {
    lastLoggedInHq,
    userId: id,
    userName: firstName,
    headquarters: userHeadquarters,
  }
}

const mapDispatchToProps = () => {
  const {
    CoreService,
    HeadquarterService,
    RestService,
    RouterService,
    SessionService,
  } = services
  const { pResponseGeneric, pResponseUser } = presenters.Api

  const changeHeadquarter = coordinators.ChangeHeadquarter({
    CoreService,
    RestService,
    RouterService,
    SessionService,
    pResponseUser,
    HandleError,
  })
  const loadHeadquarters = coordinators.LoadUserHeadquarters({
    HeadquarterService,
    RestService,
    pResponseHeadquarters: pResponseGeneric,
    HandleError,
  })
  const signOut = coordinators.SignOut({
    CoreService,
    RestService,
    RouterService,
    HandleError,
  })

  return {
    changeHeadquarter,
    loadHeadquarters,
    signOut,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))
