import { connect } from 'react-redux'

import { OrderDetail } from '@components/order'

const mapStateToProps = (state) => {
  const { order, hubspotDeal } = state

  return { order, hubspotDeal }
}

export default connect(mapStateToProps)(OrderDetail)
