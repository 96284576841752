import styled from '@emotion/styled'
import { colors } from '../../constants'

export const Label = styled.label<any>`
  color: ${colors.gray400};
  font-family: 'bold';
  text-transform: uppercase;
  font-size: ${(props) => props.fontSize || '13px'};
`

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out all;
  svg {
    margin-right: 5px;
  }
  &:hover {
    color: ${colors.blue300};
  }
`

export const PageBody = styled.div`
  display: flex;
  width: 100%;
`

export const PageContainer = styled.div`
  width: 100%;
  margin-left: 230px;
  margin-bottom: 50px;
  padding: 20px;
  overflow: auto;
`

export const PageContainerInner = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  th {
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'bold';
    color: ${colors.gray400};
    padding: 5px 10px;
    background: #eff1f6;
  }
  td {
    color: ${colors.gray400};
    vertical-align: middle;
    padding: 5px 10px;
    font-family: 'regular';
  }
`

export const ModalTitle = styled.h2`
  color: ${colors.gray400};
  font-family: 'extrabold';
  font-size: 20px;
`

export const ModalSectionTitle = styled.h3`
  font-family: 'bold';
  text-transform: uppercase;
  color: ${colors.gray400};
`

export const FormInputContainer = styled.div<any>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  margin-bottom: ${(props) => props.marginBottom};
  padding-top: ${(props) => props.paddingTop};
  display: ${(props) => (props.display ? props.display : 'flex')};
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  p {
    color: ${colors.gray300};
    font-family: 'regular';
  }
  label {
    color: ${colors.gray400};
    font-family: 'bold';
    text-transform: uppercase;
    font-size: 13px;
  }
  input,
  select,
  textarea {
    color: ${colors.gray400};
    width: 100%;
    background: #fafbff;
    font-family: 'regular';
    border: 1px solid gray;
    padding: 5px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.4;
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  input[type='file'] {
    display: none;
    opacity: 0;
  }
  .file-upload-text {
    text-transform: none;
  }
  .file-upload-button {
    cursor: pointer;
    color: ${colors.gray400};
    width: 100%;
    background: #fafbff;
    font-family: 'regular';
    border: 1px solid gray;
    padding: 5px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.4;
  }
  select {
    padding: 7px 5px;
  }
  textarea {
    height: 50px;
  }
`

export const ButtonsBar = styled.div`
  margin-bottom: 15px;
  button {
    font-family: 'regular';
    display: flex;
    align-items: center;
    margin-right: 30px;
    transition: 0.2s ease-in-out all;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding-bottom: 3px;
    margin-bottom: 5px;
    &:hover {
      border-bottom: 2px solid ${colors.orange};
    }
  }
  .auth-container {
    display: inline;
    width: auto !important;
  }
  svg {
    margin-right: 3px;
  }
`

export const ButtonsBarLegend = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-family: 'regular';
  p {
    margin-right: 20px;
  }
  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
  }
`

export const SalesTotals = styled.div`
  font-family: 'regular';
  font-size: 14px;
  p {
    margin-bottom: 5px;
  }
  span {
    display: inline-block;
    width: 200px;
  }
`

export const ColumnContainer = styled.div`
  columns: 2;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  column-gap: 25px;
`

export const OrderFormSectionTitle = styled.h2<any>`
  font-family: 'bold';
  color: ${colors.gray400};
  font-size: 20px;
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
  span {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    min-width: 25px;
    height: 25px;
    background: ${colors.orange};
    border-radius: 25px;
    margin-right: 10px;
  }
  .details {
    font-family: 'light';
    font-size: 18px;
    display: flex;
    align-items: center;
  }
`

export const PanelListItem = styled.p<any>`
  font-family: 'regular';
  color: ${colors.gray300};
  border-bottom: ${(props) =>
    props.isLast ? 'none' : `1px solid ${colors.gray200}`};
  margin-bottom: ${(props) => (props.isLast ? 0 : '10px')};
  padding-bottom: 10px;
`

export const FormContainer = styled.div<any>`
  width: 100%;
  background: ${(props) => (props.hasBackground ? '#d1eff3' : 'none')};
  padding: ${(props) => (props.hasBackground ? '10px' : 0)};
  border-radius: 4px;
  margin-bottom: 20px;
`

export const MenuItemDropdown = styled.div`
  width: 100%;
  overflow: scroll;
  height: 300px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);
  max-height: 100%;
  padding: 15px;
`

export const ChildItem = styled.div`
  display: flex;
  font-family: 'regular';
  align-items: center;
  margin-bottom: 5px;
  .child-category {
    background: ${colors.blue100};
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
    padding: 1px 5px;
    border-radius: 10px;
  }
  .close-x {
    color: ${colors.orange};
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    font-size: 17px;
  }
`

export const MultiSelectOption = styled.button`
  background: #c1eff5;
  font-size: 13px;
  font-family: 'regular';
  padding: 5px;
  padding-right: 20px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  position: relative;
  &:after {
    content: '✕';
    position: absolute;
    color: ${colors.gray400};
    right: 5px;
  }
  &:hover {
    background: ${colors.blue100};
  }
`

export type TabProps = {
  isActive?: boolean
  fontSize?: string
  width?: string
}

export const Tab = styled.button<TabProps>`
  font-family: ${(props) => (props.isActive ? 'bold' : 'regular')};
  padding: 5px 0;
  margin-right: 20px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  border-bottom: ${(props) =>
    props.isActive ? `3px solid ${colors.orange}` : '3px solid rgba(0,0,0,0)'};
  width: ${(props) => (props.width ? props.width : '')};
`

export const ResponsiveTextDiv = styled.div<any>`
  width: ${(props) => props.width || '100px'};
  word-break: break-all;
  overflow-wrap: break-word;
`

export type CloseXProps = {
  color?: string
}

export const CloseX = styled.span<CloseXProps>`
  color: ${(props) => (props.color ? props.color : colors.orange)};
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
  font-family: bold;
  font-size: 17px;
`

export const ServingWeightForm = styled.form`
  width: 31%;
  display: flex;
  flex-direction: column;
  input {
    color: ${colors.gray400};
    width: 100%;
    background: #fafbff;
    font-family: 'regular';
    border: 1px solid gray;
    padding: 5px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.4;
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  label {
    color: ${colors.gray400};
    font-family: 'bold';
    text-transform: uppercase;
    font-size: 13px;
  }
`
