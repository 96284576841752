import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from 'react-bootstrap'

import { Overview, Transactions } from '@containers/order/orderSections'
import { AuditLog } from './orderSections'

export class OrderDetail extends Component {
  state = {
    activeKey: '#overview',
  }

  render() {
    const { order, hubspotDeal } = this.props
    if (!order) {
      return null
    }
    const { activeKey } = this.state

    return (
      <Tabs
        className="tabs"
        id="order_tab"
        activeKey={activeKey}
        onSelect={(activeKey) => {
          this.setState({ activeKey })
        }}
        mountOnEnter={true}
        unmountOnExit={true}
        animation={false}
      >
        <Tab eventKey={'#overview'} title="Overview">
          <Overview order={order} hubspotDeal={hubspotDeal} />
        </Tab>

        <Tab eventKey={'#transactions'} title="Transactions">
          <Transactions transactions={order.transactions} order={order} />
        </Tab>

        <Tab eventKey={'#auditing'} title="History">
          <AuditLog audits={order.audits} />
        </Tab>
      </Tabs>
    )
  }
}

OrderDetail.propTypes = {
  hubspotDeal: PropTypes.object,
  order: PropTypes.object,
}

export default OrderDetail
