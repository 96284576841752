export const SearchPopUpCustomers =
  ({ RestService, HandleError }) =>
  async ({ headquarter, ids, search, limit = 100, offset = 0 }) => {
    const req = { limit, offset }
    if (search) {
      req.search = search
    }
    if (ids) {
      req.ids = ids
    }
    if (headquarter) {
      req.hq = headquarter
    }

    try {
      return await RestService.get('/pop-up-customers', req)
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

export const GetPopUpCustomer =
  ({ RestService, HandleError }) =>
  async (id) => {
    try {
      return await RestService.get(`/pop-up-customers/${id}`)
    } catch (error) {
      HandleError({ error })

      return undefined
    }
  }

export const SavePopUpCustomer =
  ({ RestService, UIService, HandleError }) =>
  async (customer) => {
    try {
      let resp
      if (customer.id) {
        resp = await RestService.put(
          `/pop-up-customers/${customer.id}`,
          customer,
        )
      } else {
        resp = await RestService.post('/pop-up-customers/admin', customer)
      }
      UIService.FlashMessage.displaySuccessMessage(
        `Customer ${customer.email} successfully ${
          customer.id ? 'updated' : 'created'
        }`,
      )

      return resp
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const UpsertAccountRole =
  ({ RestService, UIService, HandleError }) =>
  async (req) => {
    try {
      const customer = await RestService.put(
        '/group-orders/client-settings/account-role',
        { ...req, version: '2' },
      )
      UIService.FlashMessage.displaySuccessMessage(
        `Customer role successfully updated`,
      )

      return customer
    } catch (error) {
      HandleError({ error })

      return false
    }
  }

export const DeletePopUpCustomer =
  ({ RestService, UIService, HandleError }) =>
  async ({ id, email }) => {
    const doDelete = await UIService.ConfirmationModal.show({
      text: `Are you sure you want to delete customer with email: "${email}"?`,
    })
    if (doDelete) {
      try {
        await RestService.delete(`/pop-up-customers/${id}`)
      } catch (error) {
        HandleError({ error })

        return false
      }

      return true
    }

    return false
  }
