import React, { Fragment } from 'react'

import { Dropdown } from '@components/common/form'
import { YSpacing, FlexContainer } from '@components/common'
import { MultiSelectOption } from '@res/styledComponents/index'

interface Headquarter {
  id: number
  name: string
}

interface MarketDropdownProps {
  label: string
  onSelectHq: (value: number) => void
  onDeselectHq: (hqId: number) => void
  options: Headquarter[]
  selectedOptions: number[]
}

const MarketDropdown: React.FC<MarketDropdownProps> = ({
  label,
  onSelectHq,
  onDeselectHq,
  options,
  selectedOptions,
}) => {
  return (
    <Fragment>
      <Dropdown
        label={label}
        width="100%"
        value=""
        onChange={(e) => onSelectHq(parseInt(e.target.value))}
      >
        <option value="" disabled hidden>
          {'- Select Market(s) -'}
        </option>
        {options
          .filter((m) => !selectedOptions.includes(m.id))
          .map((m) => (
            <option key={m.id} value={m.id}>
              {m.name}
            </option>
          ))}
      </Dropdown>
      <YSpacing height="5px" />
      <FlexContainer flexWrap="wrap">
        {options
          .filter((m) => selectedOptions.includes(m.id))
          .map((m) => (
            <MultiSelectOption key={m.id} onClick={() => onDeselectHq(m.id)}>
              {m.name}
            </MultiSelectOption>
          ))}
      </FlexContainer>
    </Fragment>
  )
}

export default MarketDropdown
