import { BaseService } from 'hungry-core2'

import actions from '@actions'

export class AccountService extends BaseService {
  clearEditAccount() {
    this.dispatch(actions.clearEditAccount())
  }

  clearEditAccountContact = () => {
    this.dispatch(actions.clearEditAccountContact())
  }

  clearAccount() {
    this.dispatch(actions.clearAccount())
  }

  removeAccountFromAccounts = (accountId) => {
    this.dispatch(actions.removeAccountFromAccounts(accountId))
  }

  setEditAccount(account) {
    this.dispatch(actions.setEditAccount(account))
  }

  setEditAccountContact(account) {
    this.dispatch(actions.setEditAccountContact(account))
  }

  setAccount(account) {
    this.dispatch(actions.setAccount(account))
  }

  setAccountContacts(accountContacts) {
    this.dispatch(actions.setAccountContacts(accountContacts))
  }

  setAccounts(accounts) {
    this.dispatch(actions.setAccounts(accounts))
  }

  setAccountsInAccounts(accounts) {
    this.dispatch(actions.setAccountsInAccounts(accounts))
  }

  updateEditAccount(account) {
    this.dispatch(actions.updateEditAccount(account))
  }

  updateEditAccountContact = (contact) => {
    this.dispatch(actions.updateEditAccountContact(contact))
  }
}
