import { connect } from 'react-redux'

import { ProposalHistory } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { account } = state

  return {
    accountId: account && account.id,
  }
}

const mapDispatchToProps = () => {
  const { UIService, RestService } = services
  const { pBuildGetRequest, pRequestOrdersFilter, pResponseProposals } =
    presenters.Api
  const { GetProposalCount, SearchProposals } = coordinators

  const buildGetRequest = pBuildGetRequest
  const searchProposals = SearchProposals({
    RestService,
    pRequestProposalsFilter: pRequestOrdersFilter,
    pResponseProposals,
  })
  const { callAfterTimeout } = UIService.Timer
  const getProposalCount = GetProposalCount({
    RestService,
    pRequestProposalsFilter: pRequestOrdersFilter,
  })

  return {
    buildGetRequest,
    callAfterTimeout,
    getProposalCount,
    searchProposals,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalHistory)
