import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { TechHelpForm } from '@components/common'
import { OrderFormSectionTitle } from '@res/styledComponents/index'
import { AutocompleteInput } from '@containers/common/form'
import { FlexContainer, XSpacing, YSpacing } from '@components/common'
import DividerLine from '@components/common/DividerLine'
import {
  AccountSection,
  AddressSection,
  ContactsSection,
  OrderInfoSection,
  PaymentSection,
} from '@containers/order/edit/sections'
import LinkText from '@components/common/form/LinkText'
import { AuthorizedInteractable } from '@containers/common/auth'
import Button from '@components/common/form/Button'
import { colors } from '../../../../constants'
import { OrderablePropType } from './propTypes'

const EditOrderSection1 = (props) => {
  const {
    orderable,

    changePortions,
    loadAccountExecutives,
    loadHubspotProposals,
    renderReferralDropDown,

    onChange,
    onChangeAccountExecutive,
    onSelectSetupDate,
    onChangeAddress,
    onChangePaymentMethod,
    onCheckCarbonNeutral,
    onHide,
    onSaveAccount,
    onSaveAddress,
    onSaveContact,
    onSavePaymentMethod,
    onSelectAccount,
    onSelectContacts,
    onSelectHubspotProposal,
    onSelectOrderType,
    onSave,

    clearError,
    errors,
    saveOrderRoles,

    confirmPaymentMethod,

    nextSection,
    navigateToSection,
  } = props

  const {
    account,
    accountExecutive,
    billingAddress,
    carbonNeutral,
    cateringContact,
    chargeDate,
    clientSetUpTime,
    clientDoNotArriveBeforeTime,
    deliveryInstructions,
    dropoffAddress,
    hasNetPaymentSettings,
    headCount,
    id,
    invoiceContact,
    isEvent,
    isVCX,
    hubspotProposal,
    needsCleanup,
    needsStaffing,
    newBillingAddress,
    newCateringContact,
    newDropoffAddress,
    newInvoiceContact,
    newPaymentMethod,
    newReceiptContact,
    orderType,
    orderableType,
    paymentMethod,
    purchaseOrderNumber,
    receiptContact,
    serviceNotes,
    serviceType,
    setUpInstructions,
    setUpStyle,
  } = orderable

  const {
    addresses,
    contacts,
    clientNotes,
    cuisinePreferences,
    clientSuccessMetrics,
    paymentMethods,
  } = account || {}
  const hasAccount = !!account

  const navigateToNextSection = async () => {
    const confirmedPaymentMethod = await confirmPaymentMethod(orderable)
    if (!confirmedPaymentMethod) {
      return
    }
    navigateToSection(nextSection)
  }

  return (
    <div>
      <OrderFormSectionTitle>
        <span>1</span>Enter Your Client Info
      </OrderFormSectionTitle>
      <YSpacing height="20px" />
      <FlexContainer width="100%" justifyContent="space-between">
        <AutocompleteInput
          label="Sales Rep"
          width="31%"
          loaderFunction={loadAccountExecutives(orderType == 'VCX')}
          value={(accountExecutive && accountExecutive.name) || ''}
          onSelect={onChangeAccountExecutive}
        />
        <AccountSection
          width="31%"
          onChange={onChange}
          onSave={onSaveAccount}
          account={account}
          onSelectAccount={onSelectAccount}
        />
        {orderableType === 'Order' && id ? (
          renderReferralDropDown()
        ) : (
          <div style={{ width: '31%' }}>
            <YSpacing height="70px" />
          </div>
        )}
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer width="100%" justifyContent="space-between">
        {!isVCX && (
          <AddressSection
            addresses={addresses}
            label={'Dropoff Address'}
            dropoffAddress={dropoffAddress}
            errors={errors}
            hasAccount={hasAccount}
            newDropoffAddress={newDropoffAddress}
            onChange={onChangeAddress('dropoffAddress')}
            onSave={onSaveAddress('dropoffAddress')}
            clearError={clearError}
            width="31%"
          />
        )}
        <AddressSection
          addresses={addresses}
          label={'Billing Address'}
          dropoffAddress={billingAddress}
          errors={errors}
          hasAccount={hasAccount}
          newDropoffAddress={newBillingAddress}
          onChange={onChangeAddress('billingAddress')}
          onSave={onSaveAddress('billingAddress')}
          clearError={clearError}
          width="31%"
        />
        <PaymentSection
          paymentMethod={paymentMethod}
          paymentMethods={paymentMethods}
          errors={errors}
          hasAccount={hasAccount}
          newPaymentMethod={newPaymentMethod}
          onChange={onChangePaymentMethod}
          onSave={onSavePaymentMethod}
          clearError={clearError}
          width="31%"
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <ContactsSection
        contacts={contacts}
        invoiceContact={invoiceContact}
        receiptContact={receiptContact}
        cateringContact={cateringContact}
        errors={errors}
        isVCX={isVCX}
        hasAccount={hasAccount}
        newCateringContact={newCateringContact}
        newInvoiceContact={newInvoiceContact}
        newReceiptContact={newReceiptContact}
        onChange={onSelectContacts}
        onSave={onSaveContact}
        clearError={clearError}
      />
      <OrderInfoSection
        chargeDate={chargeDate}
        clientSetUpTime={clientSetUpTime}
        clientDoNotArriveBeforeTime={clientDoNotArriveBeforeTime}
        deliveryInstructions={deliveryInstructions}
        errors={errors}
        orderableType={orderableType}
        hasNetPaymentSettings={hasNetPaymentSettings}
        headCount={headCount}
        orderType={orderType}
        purchaseOrderNumber={purchaseOrderNumber}
        setUpInstructions={setUpInstructions}
        serviceType={serviceType}
        setUpStyle={setUpStyle}
        onChange={onChange}
        changePortions={changePortions}
        onSelectOrderType={onSelectOrderType}
        onCheckCarbonNeutral={onCheckCarbonNeutral}
        serviceNotes={serviceNotes}
        isEvent={isEvent}
        carbonNeutral={carbonNeutral}
        needsCleanup={needsCleanup}
        needsStaffing={needsStaffing}
        clientNotes={clientNotes}
        cuisinePreferences={cuisinePreferences}
        clientSuccessMetrics={clientSuccessMetrics}
        onSelectSetupDate={onSelectSetupDate}
      />

      {orderableType == 'Proposal' && (
        <div>
          <YSpacing height="20px" />
          <AutocompleteInput
            width="31%"
            label="Link to Hubspot Proposal"
            value={(hubspotProposal && hubspotProposal.name) || ''}
            loaderFunction={loadHubspotProposals}
            onSelect={onSelectHubspotProposal}
          />
        </div>
      )}
      <DividerLine margin="30px 0" />
      <YSpacing height="20px" />
      <FlexContainer alignItems="center" justifyContent="flex-end">
        <TechHelpForm margin="0 30px 0 0" />
        <LinkText
          testId="cancel"
          label="Cancel"
          onClick={onHide}
          color={colors.violet}
        />
        {id && (
          <Fragment>
            <XSpacing width="20px" />
            <AuthorizedInteractable roles={saveOrderRoles}>
              <Button
                testId="save-order"
                label={`Save ${orderableType}`}
                onClick={onSave}
              />
            </AuthorizedInteractable>
          </Fragment>
        )}
        <XSpacing width="20px" />
        <AuthorizedInteractable roles={saveOrderRoles}>
          <Button
            testId="view-available-menus"
            label={'View available menus 〉'}
            onClick={navigateToNextSection}
          />
        </AuthorizedInteractable>
      </FlexContainer>
    </div>
  )
}

EditOrderSection1.propTypes = {
  orderable: OrderablePropType,
  errors: PropTypes.object,
  saveOrderRoles: PropTypes.array,

  nextSection: PropTypes.string,
  navigateToSection: PropTypes.func,

  clearError: PropTypes.func,
  changePortions: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  loadHubspotProposals: PropTypes.func,
  renderReferralDropDown: PropTypes.func,
  confirmPaymentMethod: PropTypes.func,

  onChange: PropTypes.func,
  onChangeAccountExecutive: PropTypes.func,
  onSelectSetupDate: PropTypes.func,
  onChangeAddress: PropTypes.func,
  onChangePaymentMethod: PropTypes.func,
  onCheckCarbonNeutral: PropTypes.func,
  onHide: PropTypes.func,
  onSaveAccount: PropTypes.func,
  onSaveAddress: PropTypes.func,
  onSaveContact: PropTypes.func,
  onSavePaymentMethod: PropTypes.func,
  onSelectAccount: PropTypes.func,
  onSelectContacts: PropTypes.func,
  onSelectHubspotProposal: PropTypes.func,
  onSelectOrderType: PropTypes.func,
  onSave: PropTypes.func,
}

export default EditOrderSection1
