import React, { Component } from 'react'
import PropTypes from 'prop-types'

class TextInput extends Component {
  state = {
    isFocused: false,
  }

  onBlur = (e) => {
    const { onBlur } = this.props

    this.setState({ isFocused: false })
    onBlur && onBlur(e)
  }

  onFocus = (e) => {
    const { onFocus } = this.props

    this.setState({ isFocused: true })
    onFocus && onFocus(e)
  }

  render() {
    const { error, onChange, onInput, placeholder, testId, type, value } =
      this.props
    const { isFocused } = this.state

    return (
      <div>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          className="border w-100 regular text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
          id={testId}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={onChange}
          onInput={onInput}
        />
        {error && !isFocused && (
          <p className="text-sm bold text-red-600">{error}</p>
        )}
      </div>
    )
  }
}

TextInput.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,

  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
}

TextInput.defaultProps = {
  type: 'text',
}

export default TextInput
