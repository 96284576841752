import { connect } from 'react-redux'

import { AccountProfile } from '@components/account/accountSections'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { account } = state

  return { account }
}

const mapDispatchToProps = () => {
  const { AccountService, RestService, UIService } = services
  const { EditAccount, LoadAccountStats } = coordinators
  const {
    pResponseEditAccount,
    pResponseEditClientAccounting,
    pResponseAccountStats,
  } = presenters.Api

  const editAccount = EditAccount({
    AccountService,
    RestService,
    UIService,
    pResponseEditAccount,
    pResponseEditClientAccounting,
  })
  const loadAccountStats = LoadAccountStats({
    RestService,
    pResponseAccountStats,
  })

  return {
    editAccount,
    loadAccountStats,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile)
