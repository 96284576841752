import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PaymentSection } from '@containers/order/edit/sections'
import { RadioButton, CurrencyInput } from '@components/common/form'
import Modal from '@components/common/modal/Modal'
import loadGif from '@res/images/loading.gif'
import checkmark from '@res/images/green_check.png'

const PaymentSourceValues = [
  'Please select a payment source',
  'Braintree',
  'ACH',
  'Check',
]

const InitialState = {
  chargeType: '',
  refundAmount: '',
  paymentMethod: undefined,
  paymentMethodId: undefined,
  paymentMethods: [],
  newPaymentMethod: {},
  editingPayment: false,
  isNewPaymentMethod: false,
  orderId: undefined,

  paymentAmount: undefined,
  paymentType: undefined,
  paymentTypeState: undefined,
  paymentSource: '',
  isRefund: false,
  sendReceipt: true,
}

export class ManualChargeSection extends Component {
  state = {
    chargeType: '',
    refundAmount: '',
    paymentMethod: undefined,
    paymentMethodId: undefined,
    paymentMethods: [],
    newPaymentMethod: {},
    editingPayment: false,
    isNewPaymentMethod: false,
    orderId: undefined,

    paymentAmount: undefined,
    paymentSource: '',
    isRefund: false,
    sendReceipt: true,
  }

  componentDidMount() {
    this.props.clearErrors()
    const { paymentMethod, paymentMethods, order } = this.props

    this.setState({
      paymentMethod,
      paymentMethodId: paymentMethod && paymentMethod.id,
      paymentMethods,
      isNewPaymentMethod: paymentMethods && paymentMethods.length === 0,
      orderId: order && order.id,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { paymentMethod, paymentMethods, order } = nextProps
    const { orderId } = this.state
    const newState = {}
    const isNewOrder = orderId !== (order && order.id)

    if (paymentMethod !== this.state.paymentMethod && isNewOrder) {
      newState.paymentMethod = paymentMethod
    }
    if (paymentMethods !== this.state.paymentMethods) {
      newState.paymentMethods = paymentMethods
    }
    if (isNewOrder) {
      newState.paymentSource = InitialState.paymentSource
      newState.paymentType = InitialState.paymentType
      newState.paymentTypeState = InitialState.paymentTypeState
      newState.isRefund = InitialState.isRefund
      newState.chargeType = InitialState.chargeType
      newState.refundAmount = InitialState.refundAmount
      newState.paymentAmount = InitialState.paymentAmount
      newState.orderId = order && order.id
      this.props.clearError()
      this.props.clearErrors()
    }

    this.setState(newState)
  }

  updateState = ({ newState, updateOrder }) => {
    const { clearError } = this.props
    for (const key in newState) {
      clearError(key)
    }
    newState.didModify = true

    this.setState(newState, () => {
      updateOrder && this.updateOrder()
    })
  }

  canRefund = () => {
    const { transactions } = this.props

    return (
      transactions &&
      transactions.filter(
        (transaction) => transaction.paymentFromAllInvoice === true,
      ).length <= 0
    )
  }

  onCancel = () => {
    this.setState({ isNewPaymentMethod: false })
  }

  onChange = (newState) => {
    this.updateState({ newState, updateOrder: false })
  }

  onChangeCustomAmount = (value) => {
    this.setState({ paymentAmount: value })
  }

  onChangeSource = (e) => {
    const source = e.target.value
    const { hqRoles, displayAlert } = this.props
    if (
      ['ACH', 'Check'].includes(source) &&
      !hqRoles.some((role) => ['master admin', 'finance'].includes(role))
    ) {
      displayAlert({
        title: 'Unauthorized',
        message:
          'Manual payments are restricted to users with the Finance role',
      })
      this.setState({ paymentSource: '' })

      return
    }
    this.setState({
      paymentSource: source === PaymentSourceValues[0] ? '' : source,
    })
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked })
  }

  onChangePaymentType = (e, label) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key, [`${name}State`]: label })
  }

  onChargeClient = async () => {
    let isRefund = false
    const { chargeClient, clearError, clearErrors, order, validateOrder } =
      this.props
    const { paymentType, paymentAmount, paymentSource, sendReceipt } =
      this.state
    const canRefund = this.canRefund()
    if (paymentType === 'refundClient') {
      isRefund = true
    }
    if (isRefund && !canRefund) {
      return false
    }

    if (validateOrder(order)) {
      clearError()
      clearErrors()
      await chargeClient({
        paymentSource,
        paymentAmount,
        isRefund,
        sendReceipt,
      })

      return true
    } else {
      return false
    }
  }

  onTogglePaymentSection = (editingPayment) => () => {
    this.setState({ editingPayment })
  }

  onSavePaymentMethod = async () => {
    const { account, savePaymentMethod, validatePaymentMethod } = this.props
    let { newPaymentMethod: paymentMethod } = this.state

    if (validatePaymentMethod(paymentMethod)) {
      ;({ paymentMethod } = await savePaymentMethod({ account, paymentMethod }))
      this.updateState({
        newState: {
          paymentMethod,
          paymentMethodId: paymentMethod && paymentMethod.id,
          newPaymentMethod: {},
          isNewPaymentMethod: false,
        },
        updateOrder: true,
      })

      return true
    }

    return false
  }

  onShowNewPaymentMethod = () => {
    this.setState({ isNewPaymentMethod: true })
  }

  updateOrder = async () => {
    const { updateOrderPaymentMethod } = this.props
    const requestState = { ...this.state }
    if (requestState.paymentMethodId) {
      requestState.paymentMethod = { id: requestState.paymentMethodId }
    }
    await updateOrderPaymentMethod({
      id: this.props.order.id,
      ...requestState,
      account: this.props.account,
    })
    this.setState({ editingPayment: false })
  }

  renderUnpaid() {
    const {
      chargeType,
      paymentMethod,
      editingPayment,
      paymentSource,
      paymentType,
      paymentAmount,
      sendReceipt,
    } = this.state
    const {
      amountDue,
      chargeClientError,
      paidAmount,
      processing,
      transactions,
    } = this.props

    const canRefund = this.canRefund()

    const paymentTypes = [
      {
        label: 'Charge client',
        name: 'chargeClient',
        value: false,
      },
    ]
    if (canRefund) {
      paymentTypes.push({
        label: 'Refund client',
        value: true,
        name: 'refundClient',
      })
    }

    return (
      <div className="charge-client">
        {transactions.some((tx) => tx.paymentFromAllInvoice) && (
          <li className="mt-5">
            This order was paid via batch invoice. Please process refunds
            through the batch invoice.
          </li>
        )}
        <p className="bold mt-5">1. I want to</p>
        {paymentTypes.map((t) => (
          <RadioButton
            name="paymentType"
            label={t.label}
            value={t.name}
            key={t.label}
            checked={this.state['paymentTypeState'] === t.label}
            onChange={(e) => this.onChangePaymentType(e, t.label)}
          />
        ))}
        {paymentType && (
          <div>
            <div className="small-divider" />
            <div>
              <p className="bold">
                2. {paymentType === 'refundClient' ? 'Refund' : 'Charge'}
              </p>
              <div className="radiobutton">
                <input
                  type="radio"
                  value="fullCharge"
                  id="fullCharge"
                  checked={chargeType === 'fullCharge'}
                  name="chargeType"
                  onChange={() =>
                    this.setState({
                      chargeType: 'fullCharge',
                      paymentAmount: undefined,
                    })
                  }
                />
                <label htmlFor="fullCharge">
                  <p>
                    {' '}
                    Full amount:{' '}
                    {paymentType === 'refundClient'
                      ? `$${paidAmount}`
                      : `${amountDue}`}
                  </p>
                </label>
              </div>

              <div className="radiobutton">
                <input
                  type="radio"
                  value="customCharge"
                  id="customCharge"
                  checked={chargeType === 'customCharge'}
                  name="chargeType"
                  onChange={() => this.setState({ chargeType: 'customCharge' })}
                />
                <label htmlFor="customCharge" className="currency-radio">
                  <p>
                    {' '}
                    Custom amount{' '}
                    {chargeType === 'customCharge' && (
                      <CurrencyInput
                        value={paymentAmount}
                        onChange={this.onChangeCustomAmount}
                      />
                    )}
                  </p>
                </label>
              </div>
            </div>
            <div className="small-divider" />
          </div>
        )}
        {paymentType === 'chargeClient' && (
          <div>
            <span className="bold">3. </span>
            <select
              onChange={this.onChangeSource}
              id="select-payment-method"
              value={paymentSource || 'Please select a payment source'}
            >
              <optgroup>
                {PaymentSourceValues.map((o) => (
                  <option key={o}> {o} </option>
                ))}
              </optgroup>
            </select>
          </div>
        )}
        {paymentSource === 'Braintree' && paymentType === 'chargeClient' && (
          <div>
            <p> Payment Method currently selected: </p>
            {paymentMethod && paymentMethod.token && (
              <p> {paymentMethod.shortName} </p>
            )}
            {editingPayment ? (
              this.renderPaymentMethodEditSection()
            ) : (
              <button
                className="button-neutral"
                onClick={this.onTogglePaymentSection(true)}
              >
                {' '}
                Change Payment Method{' '}
              </button>
            )}
          </div>
        )}
        {paymentType && (
          <div>
            <div className="small-divider" />
            <div>
              <p className="bold">4. Send Receipt?</p>
              <div className="radiobutton">
                <input
                  type="radio"
                  value="sendReceipt"
                  id="sendReceipt"
                  checked={sendReceipt === true}
                  name="sendReceipt"
                  onChange={() => this.setState({ sendReceipt: true })}
                />
                <label htmlFor="sendReceipt">
                  <p> To both Hungry and Client </p>
                </label>
              </div>

              <div className="radiobutton">
                <input
                  type="radio"
                  value="noReceipt"
                  id="noReceipt"
                  checked={sendReceipt === false}
                  name="sendReceipt"
                  onChange={() => this.setState({ sendReceipt: false })}
                />
                <label htmlFor="noReceipt">
                  <p> Only to Hungry </p>
                </label>
              </div>
            </div>
            <div className="small-divider" />
          </div>
        )}
        <p />
        <div className="small-divider" />
        {(chargeType.length > 0 || paymentSource.length > 0) &&
          (chargeType !== 'customCharge' || paymentAmount > 0) && (
            <div>
              {paymentSource !== 'Braintree' ||
              (paymentMethod && paymentMethod.token && !editingPayment) ? (
                <button
                  className="button-primary"
                  disabled={processing}
                  onClick={this.onChargeClient}
                >
                  {`${
                    paymentType === 'refundClient' ? 'Refund' : 'Charge'
                  } Client`}
                </button>
              ) : (
                <p>Unable to charge client without Payment Method!</p>
              )}
            </div>
          )}
        {processing && (
          <img className="payment-load" src={loadGif} alt="loading image" />
        )}
        {chargeClientError && <p className={'red-text'}>{chargeClientError}</p>}
      </div>
    )
  }

  renderPaymentMethodEditSection() {
    const {
      paymentMethod,
      paymentMethods,
      newPaymentMethod,
      isNewPaymentMethod,
    } = this.state
    const { paymentCreationErrors, clearError } = this.props

    return (
      <div className="form-field-container">
        <PaymentSection
          paymentMethod={paymentMethod}
          paymentMethods={paymentMethods}
          errors={paymentCreationErrors}
          hasAccount={true}
          newPaymentMethod={newPaymentMethod}
          onCancel={this.onCancel}
          onChange={this.onChange}
          onSave={this.onSavePaymentMethod}
          onShowNewPaymentMethod={this.onShowNewPaymentMethod}
          clearError={clearError}
        />
        <button
          className="button-neutral"
          onClick={this.onTogglePaymentSection(false)}
        >
          {' '}
          Cancel{' '}
        </button>
        {!isNewPaymentMethod && (
          <button className="button-primary" onClick={this.updateOrder}>
            {' '}
            Save{' '}
          </button>
        )}
      </div>
    )
  }

  renderPaid() {
    return (
      <div className="center-panel charge-client">
        <img className="checkmark" src={checkmark} alt="checkmark" />
        <h3 id="paid"> Order has been paid! </h3>
      </div>
    )
  }

  render() {
    const { isPaid, hideModal } = this.props

    return (
      <Modal
        title="Charge Client & View Payment Status"
        hideModal={hideModal}
        color="#001940"
      >
        {isPaid && this.renderPaid()}
        {this.renderUnpaid()}
      </Modal>
    )
  }
}

ManualChargeSection.propTypes = {
  account: PropTypes.object,
  amountDue: PropTypes.number,
  paidAmount: PropTypes.number,
  className: PropTypes.string,
  hqRoles: PropTypes.arrayOf(PropTypes.string),
  isPaid: PropTypes.bool,
  order: PropTypes.object,
  paymentMethod: PropTypes.object,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  processing: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),

  chargeClientError: PropTypes.string,
  paymentCreationErrors: PropTypes.object,
  paymentChangeErrors: PropTypes.object,

  chargeClient: PropTypes.func,
  updateOrderPaymentMethod: PropTypes.func,
  savePaymentMethod: PropTypes.func,
  validateOrder: PropTypes.func,
  validatePaymentMethod: PropTypes.func,

  clearError: PropTypes.func,
  clearErrors: PropTypes.func,
  displayAlert: PropTypes.func,
  hideModal: PropTypes.func,
}

export default ManualChargeSection
