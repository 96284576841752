import { connect } from 'react-redux'

import { ChefDetail } from '@components/chef'

import coordinators from '@coordinators'
import presenters from '@presenters'
import services from '@services'

const mapStateToProps = (state) => {
  const { chef } = state
  const vendorOnly = (chef && chef.vendorOnly) || false

  return { chef, vendorOnly }
}

const mapDispatchToProps = () => {
  const { ChefService, RestService } = services
  const { pResponseChef, pResponseChefs } = presenters.Api
  const { LoadChefs, SelectChef } = coordinators

  const loadChefs = LoadChefs({ ChefService, RestService, pResponseChefs })
  const selectChef = SelectChef({ ChefService, RestService, pResponseChef })

  return {
    loadChefs,
    selectChef,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefDetail)
