import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

class FlashMessage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hide: false,
    }
  }

  componentDidMount() {
    if (!this.props.options) {
      this.animationTimer = setTimeout(() => {
        delete this.animationTimer
        this.setState({ hide: true })

        if (this.props.onHide) {
          // TODO - does this work?
          this.hideTimer = setTimeout(
            this.onHide,
            this.props.alertType === 'fail' ? 10000 : 350,
          )
        }
      }, 30000)
    }
  }

  onClose = () => {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer)
      delete this.hideTimer
    }
    if (this.animationTimer) {
      clearTimeout(this.animationTimer)
      delete this.animationTimer
    }

    const { onHide } = this.props
    onHide && onHide()
  }

  onHide = () => {
    const { onHide } = this.props

    delete this.hideTimer

    onHide && onHide()
  }

  render() {
    const { alertType, message, options, top } = this.props
    const { hide } = this.state

    if (options) {
      const { isLink } = options

      const _buttonAction = options && options.buttonAction
      const buttonAction = (e) => {
        e.preventDefault()
        e.stopPropagation()

        _buttonAction && _buttonAction()
        this.onClose()
      }
      const buttonTitle = options && options.buttonTitle
      if (isLink) {
        return (
          <div>
            <div
              style={{ top }}
              className={`alert-container__inner alert-${alertType} ${
                hide && 'hide-alert'
              }`}
              onClick={this.onClose}
            >
              <span className="close-flash"></span>
              <p>
                {message}{' '}
                <LinkButton onClick={buttonAction}>{buttonTitle}</LinkButton>
              </p>
            </div>
          </div>
        )
      }

      return (
        <div>
          <div
            style={{ top }}
            className={`alert-container__inner alert-${alertType} ${
              hide && 'hide-alert'
            }`}
            onClick={this.onClose}
          >
            <span className="close-flash"></span>
            <p>
              {message}{' '}
              <InnerButton onClick={buttonAction}>{buttonTitle}</InnerButton>
            </p>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div
          style={{ top }}
          className={`alert-container__inner alert-${alertType} ${
            hide && 'hide-alert'
          }`}
          onClick={this.onClose}
        >
          <p>{message}</p>
        </div>
      </div>
    )
  }
}

const LinkButton = styled.button`
  background: #1db745;
  border-bottom: 2px solid #fff;
  color: #fff;
`

const InnerButton = styled.button`
  border: none;
  margin-left: 10px;
  width: auto;
  padding: 5px;
  border-radius: 20px;
  min-width: 100px;
  font-family: 'semiBold';
  color: #3d4853;
  font-size: 15px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
`

FlashMessage.propTypes = {
  alertType: PropTypes.string,
  message: PropTypes.string,
  options: PropTypes.object,
  top: PropTypes.number,

  onHide: PropTypes.func,
}

export default FlashMessage
