import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Tabs, Tab } from 'react-bootstrap'
import {
  ChefProfile,
  Concepts,
  Contacts,
  MenuItems,
  Orders,
  Schedule,
  ServiceItems,
  SnackPacks,
  Recipes,
  VirtualItems,
  VirtualKits,
} from '@containers/chef/chefSections'

export class ChefDetail extends Component {
  state = {
    activeKey: '#overview',
  }

  componentWillReceiveProps(nextProps) {
    // If a new chef is selected reset the active tab back to overview
    if (
      nextProps.chef &&
      this.props.chef &&
      nextProps.chef.id !== this.props.chef.id
    ) {
      this.setState({ activeKey: '#overview' })
    }
  }

  render() {
    const { chef, vendorOnly } = this.props
    const { activeKey } = this.state
    if (!chef || Object.keys(chef).length === 0) {
      return null
    }

    return (
      <div className="tabs-container">
        <Tabs
          className="tabs"
          activeKey={activeKey}
          onSelect={(key) => {
            this.setState({ activeKey: key })
          }}
          mountOnEnter={true}
          unmountOnExit={true}
          animation={false}
        >
          <Tab id="chef-tab-overview" eventKey={'#overview'} title="Overview">
            <ChefProfile />
          </Tab>

          <Tab eventKey={'#schedule'} title="Schedule">
            <Schedule />
          </Tab>

          <Tab eventKey={'#menu-items'} title="Menu Items">
            <MenuItems />
          </Tab>

          <Tab eventKey={'#service-items'} title="Service Items">
            <ServiceItems />
          </Tab>

          <Tab eventKey={'#snack-packs'} title="Snack Packs">
            <SnackPacks />
          </Tab>

          <Tab eventKey={'#virtual-items'} title="Virtual Items">
            <VirtualItems />
          </Tab>

          <Tab eventKey={'#virtual-kits'} title="Virtual Kits">
            <VirtualKits />
          </Tab>

          <Tab eventKey={'#concepts'} title="Menu Cards" disabled={vendorOnly}>
            <Concepts />
          </Tab>

          <Tab id="chef-tab-contacts" eventKey={'#contacts'} title="Contacts">
            <Contacts />
          </Tab>

          <Tab eventKey={'#recipes'} title="Recipes">
            <Recipes />
          </Tab>

          <Tab id="chef-tab-orders" eventKey={'#orders'} title="Orders">
            <Orders />
          </Tab>
        </Tabs>
      </div>
    )
  }
}

ChefDetail.propTypes = {
  chef: PropTypes.object,
  vendorOnly: PropTypes.bool,
}

export default ChefDetail
