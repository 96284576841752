import { routerReducer } from 'react-router-redux'

import * as accountReducers from './account'
import * as accountsReceivableReducers from './accountsReceivable'
import * as adminUserReducers from './adminUser'
import * as batchOrderReducers from './batchOrder'
import * as chefReducers from './chef'
import * as dashboardReducers from './dashboard'
import * as groupOrderReducers from './groupOrder'
import * as groupOrderPageReducers from './groupOrderPage'
import * as headquarterReducers from './headquarter'
import * as hubspotReducers from './hubspot'
import * as invoiceReducers from './invoice'
import * as marketReducers from './market'
import * as orderReducers from './order'
import * as promoCodeReducers from './promoCodes'
import * as proposalReducers from './proposal'
import * as receiptReducers from './receipt'
import * as referralReducers from './referral'
import * as salesStatsReducers from './salesStats'
import * as serviceVendorsReducers from './serviceVendor'
import * as settingsReducers from './settings'
import * as subscriptionReducers from './subscription'
import * as supplyReducers from './supply'
import * as surveyReducers from './survey'
import uiReducers from './ui'
import { reducer as sematable } from 'sematable'

const reducers = Object.freeze({
  ...accountReducers,
  ...accountsReceivableReducers,
  ...adminUserReducers,
  ...batchOrderReducers,
  ...chefReducers,
  ...dashboardReducers,
  ...groupOrderReducers,
  ...groupOrderPageReducers,
  ...headquarterReducers,
  ...hubspotReducers,
  ...invoiceReducers,
  ...marketReducers,
  ...orderReducers,
  ...promoCodeReducers,
  ...proposalReducers,
  ...receiptReducers,
  ...referralReducers,
  ...salesStatsReducers,
  ...serviceVendorsReducers,
  ...settingsReducers,
  ...subscriptionReducers,
  ...supplyReducers,
  ...surveyReducers,
  ...uiReducers,
  router: routerReducer,
  sematable,
})

export default reducers
